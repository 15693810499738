import {
  postAxiosWithFile,
  getAxios,
  deleteAxios,
  postAxios,
  putAxios,
} from "../../util/axios-utils";

import { IMPORT_PRODUCT, FILE_PATH } from "services/APIUrl/Api";
export const uploadFile = (params, file) => {
  if (params == null) {
    params = { callTime: new Date() };
  }
  let url = IMPORT_PRODUCT;
  const request = postAxiosWithFile(url, params, file);
  return request;
};

export const GetDropDown = (params) => {
  const url = params.url;
  const request = getAxios(url).then((res) => {
    return res.data;
  });
  return request;
};
export const GetFiles = (referenceCode) => {
  const url = FILE_PATH + referenceCode;
  const request = getAxios(url).then((res) => {
    return res.data;
  });
  return request;
};

export const ItemSaveUpdate = (params) => {
  const id = params.id;
  const url = params.url;
  const data = params.data ? params.data : "";
  let request;
  if (id > 0) {
    request = putAxios(url + id, data).then((res) => {
      return res.data;
    });
  } else {
    request = postAxios(url, data).then((res) => {
      return res.data;
    });
  }

  return request;
};

export const ItemSave = (params) => {
  const url = params.url;
  const data = params.data ? params.data : "";
  let request;
  request = postAxios(url, data).then((res) => {
    return res.data;
  });
  return request;
};
export const ItemUpdate = (params) => {
  const id = params.id;
  const url = params.url + params.id;
  const data = params.data ? params.data : "";
  let request;
  if (id > 0) {
    request = putAxios(url, data).then((res) => {
      return res.data;
    });
  }

  return request;
};
export const ItemGet = (params) => {
  const url = params.url;
  const data = params.data ? params.data : "";
  const request = getAxios(url, data).then((res) => {
    return res.data;
  });
  return request;
};
export const ItemEdit = (params) => {
  const url = params.url;
  const data = params.data ? params.data : "";
  const request = getAxios(url, data).then((res) => {
    return res.data;
  });
  return request;
};
export const ItemDelete = (params) => {
  const url = params.url;
  const data = params.data ? params.data : "";
  const request = deleteAxios(url, data).then((res) => {
    return res.data;
  });
  return request;
};

export const GetReport = (params) => {
  const url = params.url;
  const data = params.data ? params.data : "";
  const request = postAxios(url, data).then((res) => {
    return res.data;
  });
  return request;
};
