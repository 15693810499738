import React from "react";
import store from "components/FormBuilder/data/store";
import ReactFormGenerator from "components/FormBuilder/form";
import fetchWrapper from "util/fetch-wrapper";
import { Modal } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
// import SelectDropDown from "components/atoms/SelectDropDown";
import MenuTreeView from "components/atoms/MenuTreeView";
const answers = {};
// const answers = {
//   'dropdown_38716F53-51AA-4A53-9A9B-367603D82548': 'd2',
//   'checkboxes_8D6BDC45-76A3-4157-9D62-94B6B24BB833': [
//     'checkboxes_option_8657F4A6-AA5A-41E2-A44A-3E4F43BFC4A6',
//     'checkboxes_option_1D674F07-9E9F-4143-9D9C-D002B29BA9E4',
//   ],
//   'radio_buttons_F79ACC6B-7EBA-429E-870C-124F4F0DA90B': [
//     'radiobuttons_option_553B2710-AD7C-46B4-9F47-B2BD5942E0C7',
//   ],
//   'rating_3B3491B3-71AC-4A68-AB8C-A2B5009346CB': 4,
// };

export default class Demobar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
      menuId: 0,
      type: 0,
    };

    const update = this._onChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    // this._onSaveFormData = this._onSaveFormData.bind(this);
    store.subscribe((state) => update(state.data));
  }
  handleChange = (data, value) => {
    this.props.onChange(value);
    this.setState({ menuId: value ? value : 0 });
  };
  showPreview() {
    this.setState({
      previewVisible: true,
      data: this.state.data,
    });

    console.log("fb", this.state.data);
    console.log("fb", JSON.stringify(this.state.data));
  }

  showShortPreview() {
    this.setState({
      shortPreviewVisible: true,
    });
  }

  showRoPreview() {
    this.setState({
      roPreviewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
      shortPreviewVisible: false,
      roPreviewVisible: false,
    });
  }
  handleMenuSelectionClick = (type) => {
    this.setState({
      type: type,
    });
  };
  _onSaveFormData() {
    let menuName = "";
    if (document.getElementById("fbMenuName") !== null) {
      menuName = document.getElementById("fbMenuName").value;
    }

    let data = {
      MenuId: this.state.menuId,
      MenuName: menuName,
      Fields: JSON.stringify(this.state.data),
    };

    fetchWrapper.post("configuration/add-page-fields", data).then((res) => {
      if (res.isSuccessful) {
        this.props.onChange(null);
      }
    });
  }
  _onChange(data) {
    this.setState({
      data: data,
    });
  }

  // eslint-disable-next-line no-unused-vars
  _onSubmit(data) {
    console.log("onSubmit", data);
    // Place code to post json data to server here
  }

  render() {
    let modalClass = "modal";
    if (this.state.previewVisible) {
      modalClass += " show d-block";
    }
    // console.log(this.state.type);
    /*  let shortModalClass = "modal short-modal";
    if (this.state.shortPreviewVisible) {
      shortModalClass += " show d-block";
    }

    let roModalClass = "modal ro-modal";
    if (this.state.roPreviewVisible) {
      roModalClass += " show d-block";
    } */

    return (
      <div className="clearfix" style={{ margin: "10px", width: "70%" }}>
        <div className="row">
          <div className="col-md-2">
            <h4 className="float-left" style={{ padding: "20px 10px" }}>
              Preview
            </h4>
          </div>
          <div className="col-md-6">
            <div className="col-md-12 p-0">
              <ButtonGroup disableElevation variant="contained" color="primary">
                <Button onClick={() => this.handleMenuSelectionClick(0)}>
                  Page Name
                </Button>
                <Button onClick={() => this.handleMenuSelectionClick(1)}>
                  Select Existing
                </Button>
              </ButtonGroup>
            </div>
            <div className="col-md-8">
              {this.state.type === 0 ? (
                <TextField id="fbMenuName" label="Menu Name" defaultValue="" />
              ) : (
                <MenuTreeView
                  treeCheckable={false}
                  onChange={this.handleChange}
                  // defaultValue={formState.values.groupId || ""}
                />
              )}
            </div>
          </div>
          <div className="col-md-4 pt-3">
            <button
              className="btn btn-primary"
              onClick={this._onSaveFormData.bind(this)}
            >
              Save Form
            </button>
            <button
              className="btn btn-primary"
              onClick={() => this.showPreview()}
            >
              Preview Form
            </button>
          </div>
        </div>

        {/*  <button
          className="btn btn-default float-right"
          style={{ marginRight: "10px" }}
          onClick={() => this.showShortPreview()}
        >
          Alternate/Short Form
        </button>
        <button
          className="btn btn-default float-right"
          style={{ marginRight: "10px" }}
          onClick={() => this.showRoPreview()}
        >
          Read Only Form
        </button>
       */}
        {this.state.previewVisible && (
          <Modal
            isOpen={this.state.previewVisible}
            // toggle={toggle}
            // className={className}
            backdrop={true}
            keyboard={true}
          >
            <div className={modalClass} role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <ReactFormGenerator
                    download_path=""
                    back_action="/"
                    // back_name="Back"
                    answer_data={answers}
                    action_name="Test From"
                    //   form_action="/api/form"
                    //   form_method="POST"
                    // skip_validations={true}
                    onSubmit={this._onSubmit}
                    variables={this.props.variables}
                    data={this.state.data}
                  />

                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                      onClick={this.closePreview.bind(this)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        {/* this.state.roPreviewVisible && (
          <Modal
            isOpen={this.state.roPreviewVisible}
            // toggle={toggle}
            // className={className}
            backdrop={true}
            keyboard={true}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  // back_action="/"
                  back_name="Back"
                  answer_data={answers}
                  action_name="Save"
                  //   form_action="/"
                  //   form_method="POST"
                  onSubmit={this._onSubmit}
                  read_only={true}
                  variables={this.props.variables}
                  hide_actions={true}
                  data={this.state.data}
                />

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={this.closePreview.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) */}

        {/* this.state.shortPreviewVisible && (
          <Modal
            isOpen={this.state.shortPreviewVisible}
            // toggle={toggle}
            // className={className}
            backdrop={true}
            keyboard={true}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <ReactFormGenerator
                  download_path=""
                  back_action=""
                  answer_data={answers}
                  //   form_action="/"
                  //   form_method="POST"
                  onSubmit={this._onSubmit}
                  data={this.state.data}
                  display_short={true}
                  variables={this.props.variables}
                  hide_actions={false}
                />

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={this.closePreview.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) */}
      </div>
    );
  }
}
