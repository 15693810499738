export const SumProperty = (arr, type) => {
  if (arr !== undefined)
    return arr.reduce((total, obj) => {
      if (typeof obj[type] === "string") {
        return total + Number(obj[type]);
      }
      return total + obj[type];
    }, 0);
};
export const FileExtension = (filename) => {
  let file_ext = /[^.]+$/.exec(filename);
  return file_ext;
};
