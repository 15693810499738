import React from "react";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Button from "@material-ui/core/Button";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import SearchBox from "components/SearchBox";
import Tooltip from "@material-ui/core/Tooltip";

class AppModuleHeader extends React.Component {
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox,
      searchText: "",
    });
  };

  constructor() {
    super();
    this.state = {
      searchBox: false,
    };
  }
  onAdd = () => {
    this.props.onAdd();
  };
  handleChange = (evt) => {
    let value = evt.target.value;
    this.setState({ searchText: value });
    this.props.onChange(value);
  };
  render() {
    const {
      placeholder,
      // onChange,
      // value,
      notification,
      apps,
      addButtonText,
      actionButton,
    } = this.props;

    let isAdd =
      actionButton !== undefined &&
      actionButton !== null &&
      actionButton.find((x) => x.name === "add");

    if (!isAdd) {
      isAdd = this.props.isAdd;
    }
    return (
      <div className="module-box-header-inner">
        <div className="search-bar right-side-icon bg-transparent d-none d-sm-block">
          <div className="form-group">
            <input
              className="form-control border-0"
              type="search"
              placeholder={placeholder}
              onChange={this.handleChange}
              value={this.state.searchText}
            />
            <button className="search-icon">
              <i className="zmdi zmdi-search zmdi-hc-lg" />
            </button>
          </div>
        </div>

        <div className="d-inline-block d-sm-none">
          <Dropdown
            className="quick-menu nav-searchbox"
            isOpen={this.state.searchBox}
            toggle={this.onSearchBoxSelect.bind(this)}
          >
            <DropdownToggle
              className="d-inline-block"
              tag="span"
              data-toggle="dropdown"
            >
              <IconButton className="icon-btn">
                <i className="zmdi zmdi-search zmdi-hc-fw text-grey" />
              </IconButton>
            </DropdownToggle>

            <DropdownMenu className="p-0">
              <SearchBox
                styleName="search-dropdown"
                placeholder=""
                onChange={this.handleChange}
                value={this.state.searchText}
              />
            </DropdownMenu>
          </Dropdown>
        </div>

        <div className="module-box-header-right">
          {apps && (
            <IconButton className="size-40" aria-label="Menu">
              <i className="zmdi zmdi-apps" />
            </IconButton>
          )}
          {notification && (
            <IconButton className="size-40" aria-label="Menu">
              <i className="zmdi zmdi-notifications-none" />
            </IconButton>
          )}

          <Tooltip title="click for advanced search">
            <IconButton
              className="size-40"
              aria-label="Menu"
              onClick={this.props.onToggleDrawer}
            >
              <i className="zmdi zmdi-filter-list zmdi-hc-fw" />
            </IconButton>
          </Tooltip>
          {isAdd && (
            <Button
              color="primary"
              // component={RouterLink}
              // to="/projects/create"
              onClick={this.onAdd}
              variant="contained"
            >
              <AddCircleOutlineIcon /> {addButtonText}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: "",
  value: "",
  notification: false,
  apps: false,
  filter: false,
  onChange: (value) => {},
};
