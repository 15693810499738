import React from "react";
import { Modal, ModalHeader } from "reactstrap";
// import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessages from "util/IntlMessages";
// import { makeStyles } from "@material-ui/core/styles";
// import InputLabel from "@material-ui/core/InputLabel";
// import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
// import MenuItem from "@material-ui/core/MenuItem";
import { Input, Divider } from "@material-ui/core";
// import validate from "validate.js";
import { ItemSaveUpdate } from "services/Common";
import { STAFF_WORKING_HOUR } from "services/APIUrl";
import Duration from "components/atoms/Duration";

// const schema = {};

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(3),
//   },
//   button: {
//     margin: theme.spacing(1, 1, 0, 0),
//   },
// }));
const AddWorkingHour = (props) => {
  // const classes = useStyles();

  const { onWorkingHourClose, open, contact } = props;
  const [anotherShift, setAnotherShift] = React.useState(false);
  const [formState, setFormState] = React.useState({
    workingHour: contact.workingHour,
  });
  const [, setElementValue] = React.useState([]);
  React.useEffect(() => {
    setElementValue(contact);
    setFormState(contact);
    return () => {};
  }, [contact]);
  const handleChangeFormState = (event) => {
    const this_elementindex = parseInt(event.target.name.split(".")[0]);
    const this_elementname = event.target.name.split(".")[1];
    let this_element = formState.workingHour;
    this_element = this_element.map((object, index) => {
      if (index === this_elementindex) {
        return {
          ...object,
          [this_elementname]: event.target.value,
        };
      } else return object;
    });
    setFormState((formState) => ({
      ...formState,
      workingHour: this_element,
    }));
    setElementValue(this_element);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (contact.staffId) {
      let params = {
        url: STAFF_WORKING_HOUR,
        data: formState,
        // id: formState.staffId,
      };
      ItemSaveUpdate(params).then((result) => {
        if (result.isSuccessful) {
          onWorkingHourClose();
        }
      });
    }
  };

  return (
    <Modal className="modal-box" toggle={onWorkingHourClose} isOpen={open}>
      <ModalHeader className="modal-box-header bg-primary text-white">
        <IntlMessages id="contact.AddWorkHourButton" />
        <IconButton className="text-white" onClick={onWorkingHourClose}>
          <CloseIcon />
        </IconButton>
      </ModalHeader>

      <div className="modal-box-content">
        <div className="row no-gutters">
          <div className="col-lg-12 ">
            <FormControl className="col-xl-6 col-lg-6 col-md-6">
              <Duration
                labelId="userType-label"
                id="userType"
                label={<IntlMessages id="contact.AddWorkHour.shiftStart" />}
                name="0.startTime"
                value={formState.workingHour[0].startTime}
                onChange={(event) => {
                  handleChangeFormState(event);
                }}
                input={<Input id="userType" />}
              ></Duration>
            </FormControl>
            <FormControl className="col-xl-6 col-lg-6 col-md-6">
              <Duration
                labelId="userType-label"
                id="userType"
                label={<IntlMessages id="contact.AddWorkHour.shiftEnd" />}
                name="0.endTime"
                value={formState.workingHour[0].endTime}
                onChange={(event) => {
                  handleChangeFormState(event);
                }}
                input={<Input id="userType" />}
              ></Duration>
            </FormControl>
          </div>

          {anotherShift ? (
            <div className="col-lg-12 ">
              <FormControl className="col-xl-6 col-lg-6 col-md-6">
                <Duration
                  labelId="userType-label"
                  id="userType"
                  label={<IntlMessages id="contact.AddWorkHour.shiftStart" />}
                  name="1.startTime"
                  value={formState.workingHour[1].startTime}
                  onChange={(event) => {
                    handleChangeFormState(event);
                  }}
                  input={<Input id="userType" />}
                ></Duration>
              </FormControl>
              <FormControl className="col-xl-6 col-lg-6 col-md-6">
                <Duration
                  labelId="userType-label"
                  id="userType"
                  label={<IntlMessages id="contact.AddWorkHour.shiftEnd" />}
                  name="1.endTime"
                  value={formState.workingHour[1].endTime}
                  onChange={(event) => {
                    handleChangeFormState(event);
                  }}
                  input={<Input id="userType" />}
                ></Duration>
              </FormControl>
              <Divider />
              <IntlMessages id="contact.AddWorkHour.breakTime" />
            </div>
          ) : (
            <Button
              variant="contained"
              color="default"
              onClick={() => {
                setAnotherShift(true);
              }}
            >
              <IntlMessages id="contact.AddWorkHourButton.addAnotherShift" />
            </Button>
          )}
        </div>
      </div>

      <div className="modal-box-footer d-flex flex-row">
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          <IntlMessages id="saveChangesButton" />
        </Button>
      </div>
    </Modal>
  );
};

export default AddWorkingHour;
