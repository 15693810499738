import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import configureStore, { history } from "./store";
// import './firebase/firebase';
import App from "./containers/App";
import { SnackbarProvider } from "notistack";
import {DragDropContextProvider as DndProvider} from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Switch>
          <DndProvider backend={HTML5Backend}>
            <Route path="/" component={App} />
          </DndProvider>
        </Switch>
      </SnackbarProvider>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
