import {
  SellActionTypes,
  SHOW_MESSAGE,
  ON_HIDE_LOADER,
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  noContentFoundMessage: "No contact found in selected folder",
  alertMessage: "",
  showMessage: false,
  activeStep: 0,
  customers: [],
  categories: [],
  itemTypes: [],
  groups: [],
  items: [],
  selectedCustomer: {},
  cartItems: [],
  paymentType: 0,
  voucherNo: "",
  voucherAmount: 0,
  totalTax: 0,
  balance: 0,
};

const sellReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SellActionTypes.FETCH_OUTLET_DATA:
      return {
        ...state,
      };
    case SellActionTypes.FETCH_OUTLET_DATA_SUCCESS:
      return {
        ...state,
        loader: false,
        customers: action.payload.customers,
        groups: action.payload.group,
      };
    case SellActionTypes.SELL_NEXT_STEP:
      return {
        ...state,
        activeStep: action.payload,
      };
    case SellActionTypes.SET_CHECKOUT_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    case SellActionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentType: action.payload,
      };
    case SellActionTypes.SET_VOUCHER_NO:
      
      return {
        ...state,
        voucherNo: action.payload.voucherNo,
        voucherAmount: action.payload.balance,
        balance: state.balance - action.payload.balance,
      };
    case SellActionTypes.REMOVE_VOUCHER_NO:
      
      return {
        ...state,
        voucherNo: "",
        voucherAmount: 0,
        balance: state.balance + state.voucherAmount,
      };
    case SellActionTypes.GET_CART_ITEM: {
      return {
        ...state,
        cartItems: state.cartItems,
      };
    }
    case SellActionTypes.ADD_CART_ITEM: {
      let cartItems = addItemIfNotExists(action.payload, state.cartItems);
      let itemsAmount = getItemAmount(cartItems);
      return {
        ...state,
        cartItems: cartItems,
        discount: itemsAmount.discount,
        subTotal: itemsAmount.subTotal,
        balance: itemsAmount.balance,
      };
    }
    case SellActionTypes.UPDATE_CART_ITEM: {
      let cartItems = updateCartItem(action.payload, state.cartItems);
      let itemsAmount = getItemAmount(cartItems);
      return {
        ...state,
        cartItems: cartItems,
        discount: itemsAmount.discount,
        subTotal: itemsAmount.subTotal,
        balance: itemsAmount.balance,
      };
    }
    case SellActionTypes.REMOVE_CART_ITEM: {
      let cartItems = removeItem(action.payload, state.cartItems);
      let itemsAmount = getItemAmount(cartItems);
      return {
        ...state,
        cartItems: cartItems,
        discount: itemsAmount.discount,
        subTotal: itemsAmount.subTotal,
        balance: itemsAmount.balance,
      };
    }
    case SellActionTypes.SUCCESS_ORDER_SAVED:
      return {
        ...state,
        cartItems: [],
        discount: 0,
        subTotal: 0,
        balance: 0,
        selectedCustomer: {},
        paymentType: 0,
        totalTax: 0,
        showMessage: true,
        voucherNo: "",
        voucherAmount: 0,
      };

    case SellActionTypes.FETCH_ITEM_SUCCESS: {
      return {
        ...state,
        items: action.payload,
      };
    }
    case ON_HIDE_LOADER: {
      return { ...state, loader: false, showMessage: false };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    default:
      return state;
  }
};

function addItemIfNotExists(cartItem, cartItems) {
  if (cartItem) {
    if (cartItem.length > 0) {
      return [...cartItems, cartItem];
    }
    const existingCartItem = cartItems.find(
      (_item) => _item.productId === cartItem.productId
    );

    if (existingCartItem) {
      return cartItems.map((_item) =>
        _item.productId === cartItem.productId &&
        _item.productType === "Product"
          ? {
              ..._item,
              quantity: (_item.quantity = cartItem.quantity
                ? _item.quantity
                : _item.quantity + 1),
            }
          : _item
      );
    }
    return [...cartItems, { ...cartItem, quantity: 1 }];
  }
}

function updateCartItem(cartItem, cartItems) {
  if (cartItem) {
    const existingCartItem = cartItems.find(
      (_item) =>
        _item.productId === cartItem.productId &&
        _item.productType === "Product"
    );

    if (existingCartItem) {
      return cartItems.map((_item) =>
        _item.productId === cartItem.productId &&
        _item.productType === "Product"
          ? {
              ..._item,
              quantity: (_item.quantity = cartItem.quantity
                ? cartItem.quantity
                : _item.quantity + 1),
            }
          : _item
      );
    } else {
      return cartItems;
    }
  }
}

function removeItem(item, cartItem) {
  let items = cartItem.filter((value) => value.id !== item.id);
  return items;
}

function getItemAmount(cartItems) {
  let discount = cartItems.reduce(function(prev, current) {
    return prev + current.discount * current.quantity;
  }, 0);
  let subTotal = cartItems.reduce(function(prev, current) {
    return prev + current.discountAmount * current.quantity;
  }, 0);
  let balance = cartItems.reduce(function(prev, current) {
    return prev + current.discountAmount * current.quantity;
  }, 0);
  return { discount: discount, subTotal: subTotal, balance: balance };
}
export default sellReducer;
