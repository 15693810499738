import {
  all,
  call,
  put,
  // fork,
  // takeEvery,
  takeLatest,
} from "redux-saga/effects";

// import {
//   facebookAuthProvider,
//   githubAuthProvider,
//   googleAuthProvider,
//   twitterAuthProvider
// } from "../firebase/firebase";
import history from "util/history";

import {
  userToken,
  getCurrentUser,
  // user_sign_up,
  LogOutUser,
  getUser,
} from "./service";
import fetchWrapper from "util/fetch-wrapper";
import { SIGNUP_BUSINESS } from "../services/APIUrl/Api";
import {
  SIGNIN_FACEBOOK_USER,
  // SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  // SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  CHECK_USER_SESSION,
} from "constants/ActionTypes";
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignUpSuccess,
  // successOrderSave,
} from "actions/Auth";
import { loadSideMenu, loadOutletSetting } from "actions/Setting";
import {
  userFacebookSignInSuccess,
  //   userGithubSignInSuccess,
  userGoogleSignInSuccess,
  //   userTwitterSignInSuccess
} from "../actions/Auth";

// const createUserWithEmailPasswordRequest = async (email, password) => {
//   await auth.createUserWithEmailAndPassword(email, password);
//   //     .then(authUser => authUser)
//   //     .catch(error => error);
// };

const signInUserWithEmailPasswordRequest = async (email, password, provider) =>
  await userToken(email, password, provider)
    .then((authUser) => authUser)
    .catch((error) => error);

const signOutRequest = () => {
  try {
    LogOutUser();
  } catch (error) {}
};

export function* isUserAuthenticated() {
  try {
    const user = yield getCurrentUser();
    if (!user) return;
    yield put(userSignInSuccess({ ...user }));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* LoginInAfterSignUp() {
  try {
    const user = yield getUser();
    if (user.data.length > 0) yield put(userSignInSuccess({ ...user.data[0] }));
    else yield put(showAuthMessage({}));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

const signInUserWithGoogleRequest = async (data) => {
  console.log(data);
};

const signInUserWithFacebookRequest = async (data) => {
  console.log(data);
};

// const signInUserWithGithubRequest = async () =>
//   await  auth.signInWithPopup(githubAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

// const signInUserWithTwitterRequest = async () =>
//   await  auth.signInWithPopup(twitterAuthProvider)
//     .then(authUser => authUser)
//     .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  //  const {email, password} = payload;
  try {
    // const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    // const signUpUser = yield user_sign_up(payload);
    const response = yield fetchWrapper.post(SIGNUP_BUSINESS, payload);
    if (response.isSuccessful) {
      yield call(forwardTo, "/signup-success");
      yield put(userSignUpSuccess(response.message));
    } else {
      // localStorage.setItem("user_id", response.data);
      yield put(showAuthMessage(response.data));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
function forwardTo(location) {
  history.push(location);
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem("user_id", signUpUser.user.uid);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

// function* signInUserWithGithub() {
//   try {
//     const signUpUser = yield call(signInUserWithGithubRequest);
//     if (signUpUser.message) {
//       yield put(showAuthMessage(signUpUser.message));
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userGithubSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

// function* signInUserWithTwitter() {
//   try {
//     const signUpUser = yield call(signInUserWithTwitterRequest);
//     if (signUpUser.message) {
//       if (signUpUser.message.length > 100) {
//         yield put(showAuthMessage('Your request has been canceled.'));
//       } else {
//         yield put(showAuthMessage(signUpUser.message));
//       }
//     } else {
//       localStorage.setItem('user_id', signUpUser.user.uid);
//       yield put(userTwitterSignInSuccess(signUpUser.user.uid));
//     }
//   } catch (error) {
//     yield put(showAuthMessage(error));
//   }
// }

function* signInUserWithEmailPassword({ payload }) {
  const { email, password, provider } = payload;
  try {
    const signInUser = yield call(
      signInUserWithEmailPasswordRequest,
      email,
      password,
      provider
    );

    if (signInUser.hasOwnProperty("error")) {
      yield put(showAuthMessage(signInUser.error_description));
    } else if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem("user_id", signInUser.userId);
      yield all([
        put(userSignInSuccess(signInUser)),
        put(loadSideMenu()),
        put(loadOutletSetting()),
      ]);
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem("user_id");
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* onCreateUserAccount() {
  yield takeLatest(SIGNUP_USER, createUserWithEmailPassword);
}
export function* onCheckUserSession() {
  yield takeLatest(CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signInWithGoogle() {
  yield takeLatest(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
  yield takeLatest(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

// export function* signInWithTwitter() {
//   yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
// }

// export function* signInWithGithub() {
//   yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
// }

export function* signInUser() {
  yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeLatest(SIGNOUT_USER, signOut);
}

export function* authSagas() {
  yield all([
    call(signInUser),
    call(onCreateUserAccount),
    call(onCheckUserSession),
    call(signInWithGoogle),
    call(signInWithFacebook),
    // fork(signInWithTwitter),
    // fork(signInWithGithub),
    call(signOutUser),
  ]);
}
