import React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
const Duration = (props) => {
  const { onHandleChange, label,value, ...rest } = props;
  const handleOnChange = (event) => {
    onHandleChange(event);
  };
  return (
   <>
      <InputLabel id="productType-label" >
       {label}
      </InputLabel>    
        <Select onChange={handleOnChange} value={value} {...rest} >
          <MenuItem value="12:00am">12:00am</MenuItem>
          <MenuItem value="12:05am">12:05am</MenuItem>
          <MenuItem value="12:10am">12:10am</MenuItem>
          <MenuItem value="12:15am">12:15am</MenuItem>
          <MenuItem value="12:20am">12:20am</MenuItem>
          <MenuItem value="12:25am">12:25am</MenuItem>
          <MenuItem value="12:30am">12:30am</MenuItem>
          <MenuItem value="12:35am">12:35am</MenuItem>
          <MenuItem value="12:40am">12:40am</MenuItem>
          <MenuItem value="12:45am">12:45am</MenuItem>
          <MenuItem value="12:50am">12:50am</MenuItem>
          <MenuItem value="12:55am">12:55am</MenuItem>
          <MenuItem value="01:00am">01:00am</MenuItem>
          <MenuItem value="01:05am">01:05am</MenuItem>
          <MenuItem value="01:10am">01:10am</MenuItem>
          <MenuItem value="01:15am">01:15am</MenuItem>
          <MenuItem value="01:20am">01:20am</MenuItem>
          <MenuItem value="01:25am">01:25am</MenuItem>
          <MenuItem value="01:30am">01:30am</MenuItem>
          <MenuItem value="01:35am">01:35am</MenuItem>
          <MenuItem value="01:40am">01:40am</MenuItem>
          <MenuItem value="01:45am">01:45am</MenuItem>
          <MenuItem value="01:50am">01:50am</MenuItem>
          <MenuItem value="01:55am">01:55am</MenuItem>
          <MenuItem value="02:00am">02:00am</MenuItem>
          <MenuItem value="02:05am">02:05am</MenuItem>
          <MenuItem value="02:10am">02:10am</MenuItem>
          <MenuItem value="02:15am">02:15am</MenuItem>
          <MenuItem value="02:20am">02:20am</MenuItem>
          <MenuItem value="02:25am">02:25am</MenuItem>
          <MenuItem value="02:30am">02:30am</MenuItem>
          <MenuItem value="02:35am">02:35am</MenuItem>
          <MenuItem value="02:40am">02:40am</MenuItem>
          <MenuItem value="02:45am">02:45am</MenuItem>
          <MenuItem value="02:50am">02:50am</MenuItem>
          <MenuItem value="02:55am">02:55am</MenuItem>
          <MenuItem value="03:00am">03:00am</MenuItem>
          <MenuItem value="03:05am">03:05am</MenuItem>
          <MenuItem value="03:10am">03:10am</MenuItem>
          <MenuItem value="03:15am">03:15am</MenuItem>
          <MenuItem value="03:20am">03:20am</MenuItem>
          <MenuItem value="03:25am">03:25am</MenuItem>
          <MenuItem value="03:30am">03:30am</MenuItem>
          <MenuItem value="03:35am">03:35am</MenuItem>
          <MenuItem value="03:40am">03:40am</MenuItem>
          <MenuItem value="03:45am">03:45am</MenuItem>
          <MenuItem value="03:50am">03:50am</MenuItem>
          <MenuItem value="03:55am">03:55am</MenuItem>
          <MenuItem value="04:00am">04:00am</MenuItem>
          <MenuItem value="04:05am">04:05am</MenuItem>
          <MenuItem value="04:10am">04:10am</MenuItem>
          <MenuItem value="04:15am">04:15am</MenuItem>
          <MenuItem value="04:20am">04:20am</MenuItem>
          <MenuItem value="04:25am">04:25am</MenuItem>
          <MenuItem value="04:30am">04:30am</MenuItem>
          <MenuItem value="04:35am">04:35am</MenuItem>
          <MenuItem value="04:40am">04:40am</MenuItem>
          <MenuItem value="04:45am">04:45am</MenuItem>
          <MenuItem value="04:50am">04:50am</MenuItem>
          <MenuItem value="04:55am">04:55am</MenuItem>
          <MenuItem value="05:00am">05:00am</MenuItem>
          <MenuItem value="05:05am">05:05am</MenuItem>
          <MenuItem value="05:10am">05:10am</MenuItem>
          <MenuItem value="05:15am">05:15am</MenuItem>
          <MenuItem value="05:20am">05:20am</MenuItem>
          <MenuItem value="05:25am">05:25am</MenuItem>
          <MenuItem value="05:30am">05:30am</MenuItem>
          <MenuItem value="05:35am">05:35am</MenuItem>
          <MenuItem value="05:40am">05:40am</MenuItem>
          <MenuItem value="05:45am">05:45am</MenuItem>
          <MenuItem value="05:50am">05:50am</MenuItem>
          <MenuItem value="05:55am">05:55am</MenuItem>
          <MenuItem value="06:00am">06:00am</MenuItem>
          <MenuItem value="06:05am">06:05am</MenuItem>
          <MenuItem value="06:10am">06:10am</MenuItem>
          <MenuItem value="06:15am">06:15am</MenuItem>
          <MenuItem value="06:20am">06:20am</MenuItem>
          <MenuItem value="06:25am">06:25am</MenuItem>
          <MenuItem value="06:30am">06:30am</MenuItem>
          <MenuItem value="06:35am">06:35am</MenuItem>
          <MenuItem value="06:40am">06:40am</MenuItem>
          <MenuItem value="06:45am">06:45am</MenuItem>
          <MenuItem value="06:50am">06:50am</MenuItem>
          <MenuItem value="06:55am">06:55am</MenuItem>
          <MenuItem value="07:00am">07:00am</MenuItem>
          <MenuItem value="07:05am">07:05am</MenuItem>
          <MenuItem value="07:10am">07:10am</MenuItem>
          <MenuItem value="07:15am">07:15am</MenuItem>
          <MenuItem value="07:20am">07:20am</MenuItem>
          <MenuItem value="07:25am">07:25am</MenuItem>
          <MenuItem value="07:30am">07:30am</MenuItem>
          <MenuItem value="07:35am">07:35am</MenuItem>
          <MenuItem value="07:40am">07:40am</MenuItem>
          <MenuItem value="07:45am">07:45am</MenuItem>
          <MenuItem value="07:50am">07:50am</MenuItem>
          <MenuItem value="07:55am">07:55am</MenuItem>
          <MenuItem value="08:00am">08:00am</MenuItem>
          <MenuItem value="08:05am">08:05am</MenuItem>
          <MenuItem value="08:10am">08:10am</MenuItem>
          <MenuItem value="08:15am">08:15am</MenuItem>
          <MenuItem value="08:20am">08:20am</MenuItem>
          <MenuItem value="08:25am">08:25am</MenuItem>
          <MenuItem value="08:30am">08:30am</MenuItem>
          <MenuItem value="08:35am">08:35am</MenuItem>
          <MenuItem value="08:40am">08:40am</MenuItem>
          <MenuItem value="08:45am">08:45am</MenuItem>
          <MenuItem value="08:50am">08:50am</MenuItem>
          <MenuItem value="08:55am">08:55am</MenuItem>
          <MenuItem value="09:00am">09:00am</MenuItem>
          <MenuItem value="09:05am">09:05am</MenuItem>
          <MenuItem value="09:10am">09:10am</MenuItem>
          <MenuItem value="09:15am">09:15am</MenuItem>
          <MenuItem value="09:20am">09:20am</MenuItem>
          <MenuItem value="09:25am">09:25am</MenuItem>
          <MenuItem value="09:30am">09:30am</MenuItem>
          <MenuItem value="09:35am">09:35am</MenuItem>
          <MenuItem value="09:40am">09:40am</MenuItem>
          <MenuItem value="09:45am">09:45am</MenuItem>
          <MenuItem value="09:50am">09:50am</MenuItem>
          <MenuItem value="09:55am">09:55am</MenuItem>
          <MenuItem value="10:00am">10:00am</MenuItem>
          <MenuItem value="10:05am">10:05am</MenuItem>
          <MenuItem value="10:10am">10:10am</MenuItem>
          <MenuItem value="10:15am">10:15am</MenuItem>
          <MenuItem value="10:20am">10:20am</MenuItem>
          <MenuItem value="10:25am">10:25am</MenuItem>
          <MenuItem value="10:30am">10:30am</MenuItem>
          <MenuItem value="10:35am">10:35am</MenuItem>
          <MenuItem value="10:40am">10:40am</MenuItem>
          <MenuItem value="10:45am">10:45am</MenuItem>
          <MenuItem value="10:50am">10:50am</MenuItem>
          <MenuItem value="10:55am">10:55am</MenuItem>
          <MenuItem value="11:00am">11:00am</MenuItem>
          <MenuItem value="11:05am">11:05am</MenuItem>
          <MenuItem value="11:10am">11:10am</MenuItem>
          <MenuItem value="11:15am">11:15am</MenuItem>
          <MenuItem value="11:20am">11:20am</MenuItem>
          <MenuItem value="11:25am">11:25am</MenuItem>
          <MenuItem value="11:30am">11:30am</MenuItem>
          <MenuItem value="11:35am">11:35am</MenuItem>
          <MenuItem value="11:40am">11:40am</MenuItem>
          <MenuItem value="11:45am">11:45am</MenuItem>
          <MenuItem value="11:50am">11:50am</MenuItem>
          <MenuItem value="11:55am">11:55am</MenuItem>
          <MenuItem value="12:00pm">12:00pm</MenuItem>
          <MenuItem value="12:05pm">12:05pm</MenuItem>
          <MenuItem value="12:10pm">12:10pm</MenuItem>
          <MenuItem value="12:15pm">12:15pm</MenuItem>
          <MenuItem value="12:20pm">12:20pm</MenuItem>
          <MenuItem value="12:25pm">12:25pm</MenuItem>
          <MenuItem value="12:30pm">12:30pm</MenuItem>
          <MenuItem value="12:35pm">12:35pm</MenuItem>
          <MenuItem value="12:40pm">12:40pm</MenuItem>
          <MenuItem value="12:45pm">12:45pm</MenuItem>
          <MenuItem value="12:50pm">12:50pm</MenuItem>
          <MenuItem value="12:55pm">12:55pm</MenuItem>
          <MenuItem value="01:00pm">01:00pm</MenuItem>
          <MenuItem value="01:05pm">01:05pm</MenuItem>
          <MenuItem value="01:10pm">01:10pm</MenuItem>
          <MenuItem value="01:15pm">01:15pm</MenuItem>
          <MenuItem value="01:20pm">01:20pm</MenuItem>
          <MenuItem value="01:25pm">01:25pm</MenuItem>
          <MenuItem value="01:30pm">01:30pm</MenuItem>
          <MenuItem value="01:35pm">01:35pm</MenuItem>
          <MenuItem value="01:40pm">01:40pm</MenuItem>
          <MenuItem value="01:45pm">01:45pm</MenuItem>
          <MenuItem value="01:50pm">01:50pm</MenuItem>
          <MenuItem value="01:55pm">01:55pm</MenuItem>
          <MenuItem value="02:00pm">02:00pm</MenuItem>
          <MenuItem value="02:05pm">02:05pm</MenuItem>
          <MenuItem value="02:10pm">02:10pm</MenuItem>
          <MenuItem value="02:15pm">02:15pm</MenuItem>
          <MenuItem value="02:20pm">02:20pm</MenuItem>
          <MenuItem value="02:25pm">02:25pm</MenuItem>
          <MenuItem value="02:30pm">02:30pm</MenuItem>
          <MenuItem value="02:35pm">02:35pm</MenuItem>
          <MenuItem value="02:40pm">02:40pm</MenuItem>
          <MenuItem value="02:45pm">02:45pm</MenuItem>
          <MenuItem value="02:50pm">02:50pm</MenuItem>
          <MenuItem value="02:55pm">02:55pm</MenuItem>
          <MenuItem value="03:00pm">03:00pm</MenuItem>
          <MenuItem value="03:05pm">03:05pm</MenuItem>
          <MenuItem value="03:10pm">03:10pm</MenuItem>
          <MenuItem value="03:15pm">03:15pm</MenuItem>
          <MenuItem value="03:20pm">03:20pm</MenuItem>
          <MenuItem value="03:25pm">03:25pm</MenuItem>
          <MenuItem value="03:30pm">03:30pm</MenuItem>
          <MenuItem value="03:35pm">03:35pm</MenuItem>
          <MenuItem value="03:40pm">03:40pm</MenuItem>
          <MenuItem value="03:45pm">03:45pm</MenuItem>
          <MenuItem value="03:50pm">03:50pm</MenuItem>
          <MenuItem value="03:55pm">03:55pm</MenuItem>
          <MenuItem value="04:00pm">04:00pm</MenuItem>
          <MenuItem value="04:05pm">04:05pm</MenuItem>
          <MenuItem value="04:10pm">04:10pm</MenuItem>
          <MenuItem value="04:15pm">04:15pm</MenuItem>
          <MenuItem value="04:20pm">04:20pm</MenuItem>
          <MenuItem value="04:25pm">04:25pm</MenuItem>
          <MenuItem value="04:30pm">04:30pm</MenuItem>
          <MenuItem value="04:35pm">04:35pm</MenuItem>
          <MenuItem value="04:40pm">04:40pm</MenuItem>
          <MenuItem value="04:45pm">04:45pm</MenuItem>
          <MenuItem value="04:50pm">04:50pm</MenuItem>
          <MenuItem value="04:55pm">04:55pm</MenuItem>
          <MenuItem value="05:00pm">05:00pm</MenuItem>
          <MenuItem value="05:05pm">05:05pm</MenuItem>
          <MenuItem value="05:10pm">05:10pm</MenuItem>
          <MenuItem value="05:15pm">05:15pm</MenuItem>
          <MenuItem value="05:20pm">05:20pm</MenuItem>
          <MenuItem value="05:25pm">05:25pm</MenuItem>
          <MenuItem value="05:30pm">05:30pm</MenuItem>
          <MenuItem value="05:35pm">05:35pm</MenuItem>
          <MenuItem value="05:40pm">05:40pm</MenuItem>
          <MenuItem value="05:45pm">05:45pm</MenuItem>
          <MenuItem value="05:50pm">05:50pm</MenuItem>
          <MenuItem value="05:55pm">05:55pm</MenuItem>
          <MenuItem value="06:00pm">06:00pm</MenuItem>
          <MenuItem value="06:05pm">06:05pm</MenuItem>
          <MenuItem value="06:10pm">06:10pm</MenuItem>
          <MenuItem value="06:15pm">06:15pm</MenuItem>
          <MenuItem value="06:20pm">06:20pm</MenuItem>
          <MenuItem value="06:25pm">06:25pm</MenuItem>
          <MenuItem value="06:30pm">06:30pm</MenuItem>
          <MenuItem value="06:35pm">06:35pm</MenuItem>
          <MenuItem value="06:40pm">06:40pm</MenuItem>
          <MenuItem value="06:45pm">06:45pm</MenuItem>
          <MenuItem value="06:50pm">06:50pm</MenuItem>
          <MenuItem value="06:55pm">06:55pm</MenuItem>
          <MenuItem value="07:00pm">07:00pm</MenuItem>
          <MenuItem value="07:05pm">07:05pm</MenuItem>
          <MenuItem value="07:10pm">07:10pm</MenuItem>
          <MenuItem value="07:15pm">07:15pm</MenuItem>
          <MenuItem value="07:20pm">07:20pm</MenuItem>
          <MenuItem value="07:25pm">07:25pm</MenuItem>
          <MenuItem value="07:30pm">07:30pm</MenuItem>
          <MenuItem value="07:35pm">07:35pm</MenuItem>
          <MenuItem value="07:40pm">07:40pm</MenuItem>
          <MenuItem value="07:45pm">07:45pm</MenuItem>
          <MenuItem value="07:50pm">07:50pm</MenuItem>
          <MenuItem value="07:55pm">07:55pm</MenuItem>
          <MenuItem value="08:00pm">08:00pm</MenuItem>
          <MenuItem value="08:05pm">08:05pm</MenuItem>
          <MenuItem value="08:10pm">08:10pm</MenuItem>
          <MenuItem value="08:15pm">08:15pm</MenuItem>
          <MenuItem value="08:20pm">08:20pm</MenuItem>
          <MenuItem value="08:25pm">08:25pm</MenuItem>
          <MenuItem value="08:30pm">08:30pm</MenuItem>
          <MenuItem value="08:35pm">08:35pm</MenuItem>
          <MenuItem value="08:40pm">08:40pm</MenuItem>
          <MenuItem value="08:45pm">08:45pm</MenuItem>
          <MenuItem value="08:50pm">08:50pm</MenuItem>
          <MenuItem value="08:55pm">08:55pm</MenuItem>
          <MenuItem value="09:00pm">09:00pm</MenuItem>
          <MenuItem value="09:05pm">09:05pm</MenuItem>
          <MenuItem value="09:10pm">09:10pm</MenuItem>
          <MenuItem value="09:15pm">09:15pm</MenuItem>
          <MenuItem value="09:20pm">09:20pm</MenuItem>
          <MenuItem value="09:25pm">09:25pm</MenuItem>
          <MenuItem value="09:30pm">09:30pm</MenuItem>
          <MenuItem value="09:35pm">09:35pm</MenuItem>
          <MenuItem value="09:40pm">09:40pm</MenuItem>
          <MenuItem value="09:45pm">09:45pm</MenuItem>
          <MenuItem value="09:50pm">09:50pm</MenuItem>
          <MenuItem value="09:55pm">09:55pm</MenuItem>
          <MenuItem value="10:00pm">10:00pm</MenuItem>
          <MenuItem value="10:05pm">10:05pm</MenuItem>
          <MenuItem value="10:10pm">10:10pm</MenuItem>
          <MenuItem value="10:15pm">10:15pm</MenuItem>
          <MenuItem value="10:20pm">10:20pm</MenuItem>
          <MenuItem value="10:25pm">10:25pm</MenuItem>
          <MenuItem value="10:30pm">10:30pm</MenuItem>
          <MenuItem value="10:35pm">10:35pm</MenuItem>
          <MenuItem value="10:40pm">10:40pm</MenuItem>
          <MenuItem value="10:45pm">10:45pm</MenuItem>
          <MenuItem value="10:50pm">10:50pm</MenuItem>
          <MenuItem value="10:55pm">10:55pm</MenuItem>
          <MenuItem value="11:00pm">11:00pm</MenuItem>
          <MenuItem value="11:05pm">11:05pm</MenuItem>
          <MenuItem value="11:10pm">11:10pm</MenuItem>
          <MenuItem value="11:15pm">11:15pm</MenuItem>
          <MenuItem value="11:20pm">11:20pm</MenuItem>
          <MenuItem value="11:25pm">11:25pm</MenuItem>
          <MenuItem value="11:30pm">11:30pm</MenuItem>
          <MenuItem value="11:35pm">11:35pm</MenuItem>
          <MenuItem value="11:40pm">11:40pm</MenuItem>
          <MenuItem value="11:45pm">11:45pm</MenuItem>
          <MenuItem value="11:50pm">11:50pm</MenuItem>
          <MenuItem value="11:55pm">11:55pm</MenuItem>
        </Select>
    
    </>
  );
};

export default Duration;
