import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import IconButton from "@material-ui/core/IconButton";
import { fileUpload } from "services/FileUpload";
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    width: "100%",
  },

  fileinput: {
    display: "none",
  },
}));
const ImageUploadInput = (props) => {
  const classes = useStyles();
  const { onFileChange,fileReferenceCode } = props;
  // const [files, setFiles] = React.useState([]);
  
  const handleFileChange = (e) => {
    let reader = new FileReader();
    let files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      reader.onloadend = () => {
        if (files) {
          const formData = new FormData();
          formData.append("image", files[0]);
          fileUpload(formData, fileReferenceCode).then((response) => {
            if (response.data.data && response.data.data.length) {
              onFileChange(response.data.data[0].fileReferenceCode);
            
            }
          });
        }
      };
      reader.readAsDataURL(files[i]);
    }
   
  };
  return (
    <>
      <input
        accept="image/*"
        onChange={handleFileChange}
        className={classes.fileinput}
        id="icon-button-file"
        type="file"
      />

      <label htmlFor="icon-button-file">
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="span"
        >
          <PhotoCamera />
        </IconButton>
      </label>
    </>
  );
};
ImageUploadInput.propTypes = {
  /**
   * fileReferenceCode
   */
  fileReferenceCode: PropTypes.string.isRequired,
    /**
   * onFileChange callback function
   */
  onFileChange: PropTypes.func.isRequired,
}
export default ImageUploadInput;
