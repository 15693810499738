import { all, call, put, takeEvery } from "redux-saga/effects";
import { loadSideMenuSuccess, loadOutletSettingSuccess } from "actions/Setting";
import { LOAD_SIDE_MENU, LOAD_OUTLET_SETTINGS } from "constants/ActionTypes";
import { SIDE_MENU, OUTLET_SETTING } from "services/APIUrl";
import { ItemGet } from "services/Common";

export function* getSideMenu() {
  try {
    let param = { url: SIDE_MENU };
    const menus = yield ItemGet(param);
    yield put(loadSideMenuSuccess(menus.data));
  } catch (error) {
    // yield put(showSellMessage(error.message));
  }
}

export function* getOutletSetting() {
  try {
    let param = { url: OUTLET_SETTING };
    const menus = yield ItemGet(param);
    yield put(loadOutletSettingSuccess(menus.data));
  } catch (error) {
    // yield put(showSellMessage(error.message));
  }
}

export function* onloadOutletSetting() {
  yield takeEvery(LOAD_OUTLET_SETTINGS, getOutletSetting);
}
export function* onloadsideMenu() {
  yield takeEvery(LOAD_SIDE_MENU, getSideMenu);
}

export function* settingsSagas() {
  yield all([call(onloadsideMenu), call(onloadOutletSetting)]);
}
