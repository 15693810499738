import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import useFetch from "effects/use-fetch.effect";
const SelectDropDown = (props) => {
  const {
    url,
    params,
    label,
    defaultValue,
    onChange,
    name,
    loader,
    options,
    placeholder,
    withEvent,
    apiStatus,
    ...rest
  } = props;

  const { status, data } = useFetch(url, params);

  return (
    <React.Fragment>
      {url && status !== "fetched" ? (
        <CircularProgress />
      ) : (
        <Autocomplete
          {...rest}
          options={options ? options : data}
          defaultValue={
            options
              ? options.find((v) => v.value === defaultValue)
              : data.find((v) => v.value === defaultValue)
          }
          getOptionLabel={(item) => item.text}
          id={`autocomplete_${name}`}
          onChange={(event, newValue) => {
            onChange(withEvent ? event : name, newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={label} placeholder={placeholder} />
          )}
        />
      )}
    </React.Fragment>
  );
};

export default SelectDropDown;

SelectDropDown.defaultProps = {
  loader: false,
  withEvent: false,
};
