import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "../reducers/Settings";
// import ChatData from '../reducers/Chat';
// import Contact from '../reducers/Contact';
// import Mail from '../reducers/Mail';
// import ToDo from '../reducers/ToDo';
import Auth from "../reducers/Auth";
// import Import from '../reducers/Import';
import orderReducer from "../reducers/Order.Reducer";
import sellReducer from "../reducers/Sell.Reducer";
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    // chatData: ChatData,
    // contacts: Contact,
    // mail: Mail,
    // toDo: ToDo,
    auth: Auth,
    // import:Import,
    orders: orderReducer,
    sell: sellReducer,
  });
