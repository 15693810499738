import React from "react";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { GetFiles } from "services/Common";
import FileItem from "./FileItem";
import { DeleteFile } from "services/FileUpload";
const File = (props) => {
  const { fileReferenceCode, isDeleteFile, className } = props;
  const [files, setFiles] = React.useState([]);
  React.useEffect(() => {
    let mounted = true;
    if (
      fileReferenceCode !== null &&
      fileReferenceCode !== "" &&
      fileReferenceCode !== "undefined"
    ) {
      GetFiles(fileReferenceCode).then((res) => {
        if (res && res.isSuccessful) {
          if (mounted) {
            setFiles(res.data);
          }
        }
      });
    }
    return () => {
      mounted = false;
    };
  }, [fileReferenceCode]);

  const handleDeleteFile = (value) => {
    DeleteFile(value).then((response) => {
      if (response && response.data.isSuccessful) {
        const listFile = files.filter(
          (item) => item.fileAttachmentId !== value
        );
        setFiles(listFile);
      }
    });
  };
  return (
    <>
      {files && files.length === 0 ? (
        <div className="h-50 align-items-center justify-content-center">
          <img
            className={className === "" ? "rounded-circle" : className}
            src="https://via.placeholder.com/150x150"
            alt="nice"
          />
        </div>
      ) : (
        <>
          {files &&
            files.map((item, index) => (
              <FileItem
                item={item}
                key={index}
                onHandleDelete={handleDeleteFile}
                isDeleteFile={isDeleteFile}
                className={className === "" ? "rounded-circle" : className}
              />
            ))}
        </>
      )}
    </>
  );
};

export default File;
