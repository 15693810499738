import { takeLatest, call, put, all } from "redux-saga/effects";
import fetchWrapper from "util/fetch-wrapper";
import {
  CUSTOMER,
  GET_GROUP,
  COMMON_MASTER,
  APP_DROPDOWN_KEY,
  PRORDUCT_SELL,
  POS_SAVE_ORDER,
} from "services/APIUrl";
import { ItemGet, GetReport } from "services/Common";
import {
  getOutletDataSuccess,
  showSellMessage,
  fetchItemSuccess,
  successOrderSave,
} from "actions/Sell.Action";

import { SellActionTypes } from "constants/ActionTypes";
function getCustomer() {
  let param = { url: CUSTOMER };
  return ItemGet(param);
}
function getGroup() {
  let param = { url: GET_GROUP };
  return ItemGet(param);
}
function getItemTypes() {
  let param = { url: COMMON_MASTER + APP_DROPDOWN_KEY.ProductType };
  return ItemGet(param);
}
function getItems(payload) {
  let data = payload.payload;
  let param = { url: PRORDUCT_SELL, data: data };
  return GetReport(param);
}
export function* fetchOutletData() {
  try {
    const itemTypes = yield getItemTypes();
    const customer = yield getCustomer();
    const group = yield getGroup();
    yield put(
      getOutletDataSuccess({
        customers: customer.data,
        group: group.data,
        itemTypes: itemTypes.data,
      })
    );
  } catch (error) {
    yield put(showSellMessage(error.message));
  }
}
export function* fetchItem(payload) {
  try {
    const items = yield getItems(payload);
    yield put(fetchItemSuccess(items.data));
  } catch (error) {
    yield put(showSellMessage(error.message));
  }
}
export function* OrderSave(params) {
  try {
    
    const response = yield fetchWrapper.post(POS_SAVE_ORDER, params.payload);
    if (response.isSuccessful) {
      yield put(successOrderSave());
    }
  } catch (error) {
    yield put(showSellMessage(error.message));
  }
}
export function* onOrderSave() {
  yield takeLatest(SellActionTypes.SAVE_POS_CART, OrderSave);
}
export function* onfetchItem() {
  yield takeLatest(SellActionTypes.FETCH_ITEM, fetchItem);
}
export function* onfetchOutletData() {
  yield takeLatest(SellActionTypes.FETCH_OUTLET_DATA, fetchOutletData);
}

export function* sellSagas() {
  yield all([call(onfetchOutletData), call(onfetchItem), call(onOrderSave)]);
}
