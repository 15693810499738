import React from 'react';
import ContactCell from "./ContactCell/index";

const ContactList = ({contactList, addFavourite, onContactSelect,onEditContact , onSaveContact, onDeleteContact,dropdown}) => {
  return (
    <div className="contact-main-content">
      {contactList.map((contact, index) =>
        <ContactCell key={index} contact={contact} onDeleteContact={onDeleteContact}
                     onSaveContact={onSaveContact}  
                     addFavourite={addFavourite} onContactSelect={onContactSelect} dropdown={dropdown} onEditContact={onEditContact}/>
      )}

    </div>
  )
};

export default ContactList;