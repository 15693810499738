import { takeLatest, call, put, all } from "redux-saga/effects";
import {
  PAGE_CONFIGURATION,
  PAGE_REPORT,
  ORDER_STATUSUPDATE,
  PAGE_ViewData,
} from "services/APIUrl";
import { GetReport, ItemSave, ItemGet } from "services/Common";
import {
  fetchOrdersSuccess,
  fetchPageConfigurationSucess,
  showOrderMessage,
  onOrderUpdateSucess,
  fetchOrderViewDetailsSucess,
} from "actions/Order.Action";

import { OrderActionTypes } from "constants/ActionTypes";

export function* fetchOrderPageConfiguration() {
  try {
    let param = { url: PAGE_CONFIGURATION + 8 };
    const response = yield ItemGet(param);
    //  console.log(orderPageConfig);
    yield put(fetchPageConfigurationSucess(response.data));
  } catch (error) {
    yield put(showOrderMessage(error.message));
  }
}
export function* fetchOrdersAsync(payload) {
  try {
    console.log("Order fetching...");
    let data = payload.payload;
    let param = { url: PAGE_REPORT, data: data };
    const response = yield GetReport(param);
    yield put(fetchOrdersSuccess(response));
  } catch (error) {
    yield put(showOrderMessage(error.message));
  }
}
export function* orderStatusUpdate(payload) {
  try {
    let param = { url: ORDER_STATUSUPDATE, data: payload.payload };
    const response = yield ItemSave(param);
    yield put(onOrderUpdateSucess(response));
  } catch (error) {
    yield put(showOrderMessage(error.message));
  }
}
export function* fetchOrderViewDetails(payload) {
  try {
    let param = {
      url: PAGE_ViewData,
      data: { SectionId: 5, Id: payload.payload },
    };

    const response = yield GetReport(param);
    yield put(fetchOrderViewDetailsSucess(response));
  } catch (error) {
    yield put(showOrderMessage(error.message));
  }
}

export function* onfetchOrderViewDetails() {
  yield takeLatest(
    OrderActionTypes.ON_ORDER_VIEW_DETAIL,
    fetchOrderViewDetails
  );
}
export function* onOrderStatusUpdate() {
  yield takeLatest(OrderActionTypes.ON_ORDER_UPDATE, orderStatusUpdate);
}
export function* onfetchOrdersStart() {
  yield takeLatest(OrderActionTypes.FETCH_ALL_ORDER, fetchOrdersAsync);
}

export function* onfetchPageConfiguraton() {
  yield takeLatest(
    OrderActionTypes.FETCH_PAGE_CONFIGURATION_START,
    fetchOrderPageConfiguration
  );
}

export function* orderSagas() {
  yield all([
    call(onfetchPageConfiguraton),
    call(onfetchOrdersStart),
    call(onOrderStatusUpdate),
    call(onfetchOrderViewDetails),
  ]);
}
