import React from "react";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import fetchWrapper from "util/fetch-wrapper";
import { SIGNUP_VERIFYOTP } from "../services/APIUrl/Api";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import { FormControl, TextField, Button } from "@material-ui/core";

const SignUpSuccess = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, errors: fieldsErrors } = useForm();

  const [verifyOtp, setVerifyOtp] = React.useState(false);
  const onSubmitOTP = async (data) => {
    let url = SIGNUP_VERIFYOTP + "/" + props.location.state.id + "/" + data.otp;
    const response = await fetchWrapper.post(url);
    if (response.isSuccessful) {
      setVerifyOtp(true);
      enqueueSnackbar("successfully signup", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.data, {
        variant: "error",
      });
    }
  };
  return (
    // <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
    // <div className="app-login-main-content">
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-content text-center">
        <div className="app-logo-content login-header w-100">
          <Link className="app-logo" to="/app/app-module/login-1" title="Demo">
            <img
              src={require("assets/images/logo.png")}
              alt="favsha"
              title="favsha"
            />
          </Link>
        </div>

        <div className="mb-4">
          {verifyOtp === false ? (
            <React.Fragment>
              <h3>You are successfully Sign up</h3>
              <p>
                <IntlMessages id="appModule.signUp.emailverify" />
              </p>
              <form onSubmit={handleSubmit(onSubmitOTP)}>
                <FormControl fullWidth margin="normal" className="mt-0 mb-2">
                  <Controller
                    name="otp"
                    defaultValue=""
                    as={
                      <TextField
                        id="otp"
                        type="text"
                        helperText={
                          fieldsErrors.otp ? fieldsErrors.otp.message : null
                        }
                        label={<IntlMessages id="appModule.signUp.otp" />}
                        error={fieldsErrors.otp}
                      />
                    }
                    control={control}
                    rules={{
                      required: "is required",
                    }}
                  />
                </FormControl>
                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" type="submit" color="primary">
                    <IntlMessages id="appModule.form.submit" />
                  </Button>
                  <Link className="right-arrow" to="/signin">
                    <IntlMessages id="signUp.alreadyMember" />
                  </Link>
                </div>
              </form>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <h3>Your Email Successfully verify</h3>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
export default SignUpSuccess;
