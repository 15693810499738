//import {INSERT_ORDER_ADDRESS  } from './Types'
import { deleteAxios, postAxiosWithFile } from "../../util/axios-utils";
export const DeleteFile = (params) => {
  if (params == null) {
    params = { callTime: new Date() };
  }
  let url = "File/" + params;
  const request = deleteAxios(url);
  return request;
};
export const fileUpload = (params, file) => {
  if (params == null) {
    params = { callTime: new Date() };
  }
  let url = "FileAttach?FileReferenceCode=" + file;
  const request = postAxiosWithFile(url, params);
  return request;
};
