import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
 import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme) => ({
  formControl: {
    'width':'100%'
  }
}));

const CustomSelect = (props) => {
  const { Items,id, label,onChange, ...rest } = props;
   const classes = useStyles();
  const [value, setValue] = React.useState("");

  const handleChange = (event) => {
   setValue(event.target.value);
    onChange(event.target.value);
  };

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select       
        id={id}
        value={value}
        onChange={handleChange}
        label={label}
        {...rest}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {Items.map((item, i) => (
          <MenuItem key={i} value={item.value}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

CustomSelect.propTypes = {
   value: PropTypes.string.isRequired,
   onChange:PropTypes.func.isRequired,
};
export default CustomSelect;
