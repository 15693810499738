import {
  SellActionTypes,
  SHOW_MESSAGE,
  ON_HIDE_LOADER,
} from "constants/ActionTypes";

export const showSellMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const hideMessage = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const setStep = (data) => {
  return {
    type: SellActionTypes.SELL_NEXT_STEP,
    payload: data,
  };
};
export const getOutletData = () => {
  return {
    type: SellActionTypes.FETCH_OUTLET_DATA,
  };
};
export const getOutletDataSuccess = (data) => {
  return {
    type: SellActionTypes.FETCH_OUTLET_DATA_SUCCESS,
    payload: data,
  };
};
export const setCheckOutCustomer = (data) => {
  return {
    type: SellActionTypes.SET_CHECKOUT_CUSTOMER,
    payload: data,
  };
};
export const setPaymentMethod = (data) => {
  return {
    type: SellActionTypes.SET_PAYMENT_METHOD,
    payload: data,
  };
};
export const setVoucherNo = (data) => {
  return {
    type: SellActionTypes.SET_VOUCHER_NO,
    payload: data,
  };
};
export const removeVoucherNo = () => {
  return {
    type: SellActionTypes.REMOVE_VOUCHER_NO,
  };
};
export const getCartItem = () => {
  return {
    type: SellActionTypes.GET_CART_ITEM,
  };
};
export const addCartItem = (data) => {
  return {
    type: SellActionTypes.ADD_CART_ITEM,
    payload: data,
  };
};
export const updateCartItem = (data) => {
  return {
    type: SellActionTypes.UPDATE_CART_ITEM,
    payload: data,
  };
};
export const removeCartItem = (data) => {
  return {
    type: SellActionTypes.REMOVE_CART_ITEM,
    payload: data,
  };
};
export const successOrderSave = () => {
  return {
    type: SellActionTypes.SUCCESS_ORDER_SAVED,
  };
};
export const fetchItem = (data) => {
  return {
    type: SellActionTypes.FETCH_ITEM,
    payload: data,
  };
};
export const fetchItemSuccess = (data) => {
  return {
    type: SellActionTypes.FETCH_ITEM_SUCCESS,
    payload: data,
  };
};

export const OrderSave = () => {
  return {
    type: SellActionTypes.SUCCESS_ORDER_SAVED,
  };
};
