import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import AddContact from "../../AddContact";
import AddWorkingHour from "../../AddWorkingHour";
import File from "components/atoms/File";
import { CONTACT_DETAIL, STAFF_WORKING_HOUR } from "services/APIUrl";
import { ItemGet } from "services/Common";
import IntlMessages from "util/IntlMessages";
class ContactCell extends React.Component {
  onContactOptionSelect = (event) => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };
  handleRequestClose = () => {
    this.setState({ menuState: false });
  };
  onContactClose = () => {
    this.setState({ addContactState: false });
  };
  onDeleteContact = (contact) => {
    this.setState({ addContactState: false });
    this.props.onDeleteContact(contact);
  };
  onEditContact = (contact) => {
    let param = { url: CONTACT_DETAIL + contact.id };
    ItemGet(param).then((response) => {
      if (response.data && response.data.length) {
        this.setState({
          menuState: false,
          addContactState: true,
          contactDetail: response.data[0],
        });
      }
    });
  };
  onAddWorkHour = (contact) => {
    let param = { url: STAFF_WORKING_HOUR + contact.id };
    ItemGet(param).then((response) => {
      if (response.data) {
        this.setState({
          menuState: false,
          addWorkingHourState: true,
          contactWorkingHourDetail: response.data,
        });
      }
    });
  };

  onWorkingHourClose = () => {
    this.setState({ addWorkingHourState: false });
  };
  onSaveWorkingHour = () => {
    this.setState({ addWorkingHourState: false });
  };
  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      menuState: false,
      addContactState: false,
      userType: "",
      contactDetail: {},
    };
  }

  render() {
    const {
      contact,
      addFavourite,
      onSaveWorkingHour,
      onContactSelect,
      onSaveContact,
      key,
      dropdown,
    } = this.props;
    const {
      addWorkingHourState,
      contactWorkingHourDetail,
      addContactState,
      contactDetail,
    } = this.state;
    const {
      name,
      thumb,
      email,
      phone,
      designation,
      starred,
      workingHour,
    } = contact;

    // const options = [
    //   'Edit',
    //   'Add Work Hour',
    //   'Delete',
    // ];
    return (
      <div className="contact-item">
        <Checkbox
          className="d-none"
          color="primary"
          checked={contact.selected}
          value="checkedF"
          onClick={() => {
            onContactSelect(contact);
          }}
        />
        <div className="col-auto px-1 actions d-none d-xs-flex">
          <IconButton
            className="icon-btn p-1"
            onClick={() => {
              addFavourite(contact);
            }}
          >
            {starred ? (
              <i className="zmdi zmdi-star" />
            ) : (
              <i className="zmdi zmdi-star-outline" />
            )}
          </IconButton>
        </div>

        <div
          className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
          style={{ fontSize: 20 }}
        >
          {name.charAt(0).toUpperCase()}
        </div>

        <div className="col ">
          <p className="mb-0">
            <span className="text-truncate contact-name text-dark">{name}</span>
            <span className="d-inline-block toolbar-separator">&nbsp;</span>
            <span className="text-truncate job-title text-dark">
              {designation}
            </span>
          </p>

          <div className="text-muted">
            <span className="email d-inline-block mr-2">{email},</span>

            <span className="phone d-inline-block">{phone}</span>
          </div>
          <div className="text-muted">
            <span className="email d-inline-block mr-2">{workingHour}</span>
          </div>
        </div>
        {thumb === null || thumb === "" ? null : (
          <div className="col col-2">
            <File fileReferenceCode={thumb} />
          </div>
        )}
        <div className="col-auto px-1 actions d-none d-sm-flex">
          <Button
            onClick={() => {
              this.onAddWorkHour(contact);
            }}
          >
            {" "}
            <IntlMessages id="contact.AddWorkHourButton" />
          </Button>

          {/* <IconButton
            className="icon-btn p-2"
            onClick={this.onContactOptionSelect}
          >
            <i className="zmdi zmdi-more-vert" />
          </IconButton> <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={menuState}
            onClose={this.handleRequestClose}
            MenuListProps={{
              style: {
                width: 100,
              },
            }}
          >
            <MenuItem
              onClick={() => {
                this.onEditContact(contact);
              }}
            >
              {" "}
              <IntlMessages id="editButton" />
            </MenuItem>

            {designation === "Staff" ? (
              <MenuItem
                onClick={() => {
                  this.onAddWorkHour(contact);
                }}
              >
                {" "}
                <IntlMessages id="contact.AddWorkHourButton" />
              </MenuItem>
            ) : null}

            <MenuItem
              onClick={() => {
                this.handleRequestClose();
                this.onDeleteContact(contact);
              }}
            >
              {" "}
              <IntlMessages id="deleteButton" />
            </MenuItem>
            </Menu> */}
          {addContactState && contactDetail && (
            <AddContact
              key={key}
              open={addContactState}
              contact={contactDetail}
              onSaveContact={onSaveContact}
              onContactClose={this.onContactClose}
              onDeleteContact={this.onDeleteContact}
              dropdown={dropdown}
            />
          )}

          {addWorkingHourState && contactWorkingHourDetail && (
            <AddWorkingHour
              key={key}
              open={addWorkingHourState}
              contact={contactWorkingHourDetail}
              onSaveWorkingHour={onSaveWorkingHour}
              onWorkingHourClose={this.onWorkingHourClose}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ContactCell;
