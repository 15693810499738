import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";
// import ErrorBoundary from "components/ErrorBoundary";
const CatalogProduct = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/product`} />
      <Route
        path={`${match.url}/product-4`}
        component={asyncComponent(() => import("./routes/product/index"))}
      />
      <Route
        path={`${match.url}/group-11`}
        component={asyncComponent(() => import("./routes/group"))}
      />
      <Route
        path={`${match.url}/productprize-53`}
        component={asyncComponent(() => import("./routes/prize"))}
      />
      <Route
        path={`${match.url}/winners-54`}
        component={asyncComponent(() => import("./routes/winners"))}
      />
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default CatalogProduct;
