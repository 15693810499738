// eslint-disable-next-line max-classes-per-file
import React from "react";
import Select from "react-select";
import xss from "xss";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";
import fetchWrapper from "util/fetch-wrapper";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import moment from "moment";
// import Select from
import NativeSelect from "@material-ui/core/NativeSelect";
// import MUISelect from "@material-ui/core/Select";
import { InputLabel } from "@material-ui/core";
// import SignaturePad from "react-signature-canvas";
// import ReactBootstrapSlider from "react-bootstrap-slider";
import ReactDatePicker from "react-datepicker";
import StarRating from "./star-rating";
import HeaderBar from "./header-bar";
const FormElements = {};
const myxss = new xss.FilterXSS({
  whiteList: {
    u: [],
    br: [],
    b: [],
    i: [],
    ol: ["style"],
    ul: ["style"],
    li: [],
    p: ["style"],
    sub: [],
    sup: [],
    div: ["style"],
    em: [],
    strong: [],
    span: ["style"],
  },
});

const ComponentLabel = (props) => {
  const hasRequiredLabel =
    props.data.hasOwnProperty("required") &&
    props.data.required === true &&
    !props.read_only;

  return (
    <InputLabel htmlFor={props.id}>
      {myxss.process(props.data.label)}
      {hasRequiredLabel && (
        <span className="label-required badge badge-danger">Required</span>
      )}
    </InputLabel>
    /*  <label className={props.className || ""}>
      <span
        dangerouslySetInnerHTML={{ __html: myxss.process(props.data.label) }}
      />
      {hasRequiredLabel && (
        <span className="label-required badge badge-danger">Required</span>
      )}
      </label> */
  );
};

const ComponentHeader = (props) => {
  if (props.mutable) {
    return null;
  }
  return (
    <div>
      {props.data.pageBreakBefore && (
        <div className="preview-page-break">Page Break</div>
      )}
      <HeaderBar
        parent={props.parent}
        editModeOn={props.editModeOn}
        data={props.data}
        onDestroy={props._onDestroy}
        onEdit={props.onEdit}
        static={props.data.static}
        required={props.data.required}
      />
    </div>
  );
};

class Header extends React.Component {
  render() {
    // const headerClasses = `dynamic-input ${this.props.data.element}-input`;
    let classNames = "static";
    if (this.props.data.bold) {
      classNames += " bold";
    }
    if (this.props.data.italic) {
      classNames += " italic";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <h3
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: myxss.process(this.props.data.content),
          }}
        />
      </div>
    );
  }
}

class Paragraph extends React.Component {
  render() {
    let classNames = "static";
    if (this.props.data.bold) {
      classNames += " bold";
    }
    if (this.props.data.italic) {
      classNames += " italic";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <p
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: myxss.process(this.props.data.content),
          }}
        />
      </div>
    );
  }
}

class Label extends React.Component {
  render() {
    let classNames = "static";
    if (this.props.data.bold) {
      classNames += " bold";
    }
    if (this.props.data.italic) {
      classNames += " italic";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <label
          className={classNames}
          dangerouslySetInnerHTML={{
            __html: myxss.process(this.props.data.content),
          }}
        />
      </div>
    );
  }
}

class LineBreak extends React.Component {
  render() {
    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <hr />
      </div>
    );
  }
}

class TextInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.state = { value: "" };
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  componentWillReceiveProps(props) {
    if (props.defaultValue !== !this.state.value) {
      this.setState({ value: props.defaultValue });
    }
  }
  render() {
    const props = {};
    props.type = "text";
    props.className = "form-control";
    props.name = this.props.data.field_name;
    props.onChange = this.handleChange;
    if (this.props.mutable) {
      props.value = this.state.value;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    if (this.props.read_only) {
      props.disabled = "disabled";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {/*<input {...props} /> <TextField {...props} /> */}
          <TextField {...props} />
        </div>
      </div>
    );
  }
}

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.state = { value: "" };
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  componentWillReceiveProps(props) {
    if (props.defaultValue !== !this.state.value) {
      this.setState({ value: props.defaultValue });
    }
  }
  render() {
    const props = {};
    props.type = "number";
    props.className = "form-control";
    props.onChange = this.handleChange;
    props.name = this.props.data.field_name;
    props.InputLabelProps = {
      shrink: true,
    };
    if (this.props.mutable) {
      props.value = this.state.value;
      props.ref = this.inputField;
    }

    if (this.props.read_only) {
      props.disabled = "disabled";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {/*<input {...props} />  */}
          <TextField {...props} />
        </div>
      </div>
    );
  }
}

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.state = { value: "" };
  }
  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  componentWillReceiveProps(props) {
    if (props.defaultValue !== !this.state.value) {
      this.setState({ value: props.defaultValue });
    }
  }
  render() {
    const props = {};
    props.className = "form-control";
    props.onChange = this.handleChange;
    props.name = this.props.data.field_name;
    props.multiline = true;
    if (this.props.read_only) {
      props.disabled = "disabled";
    }

    if (this.props.mutable) {
      props.value = this.state.value;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {/*<input {...props} />  */}
          <TextField {...props} />
        </div>
      </div>
    );
  }
}

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();

    this.updateFormat(props);
    this.state = this.updateDateTime(props, this.formatMask);
  }

  formatMask = "";

  handleChange = (dt) => {
    let placeholder;
    if (dt && dt.target) {
      placeholder =
        dt && dt.target && dt.target.value === ""
          ? this.formatMask.toLowerCase()
          : "";
      const formattedDate = dt.target.value
        ? format(dt.target.value, this.formatMask)
        : "";
      this.setState({
        value: formattedDate,
        internalValue: formattedDate,
        placeholder,
      });
    } else {
      this.setState({
        value: dt ? format(dt, this.formatMask) : "",
        internalValue: dt,
        placeholder,
      });
    }
  };

  updateFormat(props) {
    const { showTimeSelect, showTimeSelectOnly } = props.data;
    const dateFormat =
      showTimeSelect && showTimeSelectOnly ? "" : props.data.dateFormat;
    const timeFormat = showTimeSelect ? props.data.timeFormat : "";
    const formatMask = `${dateFormat} ${timeFormat}`.trim();
    const updated = formatMask !== this.formatMask;
    this.formatMask = formatMask;
    return updated;
  }

  updateDateTime(props, formatMask) {
    let value;
    let internalValue;
    const { defaultToday } = props.data;
    if (
      defaultToday &&
      (props.defaultValue === "" || props.defaultValue === undefined)
    ) {
      value = format(new Date(), formatMask);
      internalValue = new Date();
    } else {
      value = props.defaultValue;

      if (value === "" || value === undefined) {
        internalValue = undefined;
      } else {
        internalValue = parse(value, this.formatMask, new Date());
      }
    }
    return {
      value,
      internalValue,
      placeholder: formatMask.toLowerCase(),
      defaultToday,
    };
  }

  componentWillReceiveProps(props) {
    const formatUpdated = this.updateFormat(props);
    if (props.data.defaultToday !== !this.state.defaultToday || formatUpdated) {
      const state = this.updateDateTime(props, this.formatMask);
      this.setState(state);
    }
  }

  render() {
    const { showTimeSelect, showTimeSelectOnly } = this.props.data;
    const props = {};
    props.type = "date";
    props.className = "form-control";
    props.name = this.props.data.field_name;
    const readOnly = this.props.data.readOnly || this.props.read_only;
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const placeholderText = this.formatMask.toLowerCase();

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <div>
            {readOnly && (
              <input
                type="text"
                name={props.name}
                ref={props.ref}
                readOnly={readOnly}
                placeholder={this.state.placeholder}
                value={this.state.value}
                className="form-control"
              />
            )}
            {iOS && !readOnly && (
              <input
                type="date"
                name={props.name}
                ref={props.ref}
                onChange={this.handleChange}
                dateFormat="MM/DD/YYYY"
                placeholder={this.state.placeholder}
                value={this.state.value}
                className="form-control"
              />
            )}
            {!iOS && !readOnly && (
              <ReactDatePicker
                name={props.name}
                ref={props.ref}
                onChange={this.handleChange}
                selected={this.state.internalValue}
                todayButton={"Today"}
                className="form-control"
                isClearable={true}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                dateFormat={this.formatMask}
                portalId="root-portal"
                placeholderText={placeholderText}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
/**
class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultValue: props.defaultValue,
    };
    this.inputField = React.createRef();
    this.canvas = React.createRef();
  }

  clear = () => {
    if (this.state.defaultValue) {
      this.setState({ defaultValue: '' });
    } else if (this.canvas.current) {
      this.canvas.current.clear();
    }
  }

  render() {
    const { defaultValue } = this.state;
    let canClear = !!defaultValue;
    const props = {};
    props.type = 'hidden';
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = defaultValue;
      props.ref = this.inputField;
    }
    const pad_props = {};
    // umd requires canvasProps={{ width: 400, height: 150 }}
    if (this.props.mutable) {
      pad_props.defaultValue = defaultValue;
      pad_props.ref = this.canvas;
      canClear = !this.props.read_only;
    }

    let baseClasses = 'SortableItem rfb-item';
    if (this.props.data.pageBreakBefore) { baseClasses += ' alwaysbreak'; }

    let sourceDataURL;
    if (defaultValue && defaultValue.length > 0) {
      sourceDataURL = `data:image/png;base64,${defaultValue}`;
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          {this.props.read_only === true || !!sourceDataURL
            ? (<img src={sourceDataURL} />)
            : (<SignaturePad {...pad_props} />)
          }
          { canClear && (
            <i className="fas fa-times clear-signature" onClick={this.clear} title="Clear Signature"></i>) }
          <input {...props} />
        </div>
      </div>
    );
  }
}
*/

class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    const { defaultValue, data } = props;
    this.state = { value: this.getDefaultValue(defaultValue, data.options) };
  }

  getDefaultValue(defaultValue, options) {
    if (defaultValue) {
      if (typeof defaultValue === "string") {
        const vals = defaultValue.split(",").map((x) => x.trim());
        return options.filter((x) => vals.indexOf(x.value) > -1);
      }
      return options.filter((x) => defaultValue.indexOf(x.value) > -1);
    }
    return [];
  }

  // state = { value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [] };

  handleChange = (e) => {
    this.setState({ value: parseInt(e.value) });
  };

  async getDropdownOptions(url) {
    const response = await fetchWrapper.get(url);
    if (response.isSuccessful) {
      this.setState({ data: response.data, value: this.props.defaultValue });
    }
  }

  componentDidMount() {
    if (this.props.data.apiUrl) {
      this.getDropdownOptions(this.props.data.apiUrl);
    }
  }

  render() {
    let options = [];
    if (
      this.props.data.options &&
      (this.props.data.apiUrl === "" || this.props.data.apiUrl === undefined)
    ) {
      options = this.props.data.options.map((option) => {
        option.label = option.text;
        return option;
      });
    } else if (this.state.data) {
      options = this.state.data;
    }

    const props = {};
    props.isMulti = false;
    props.name = this.props.data.field_name;
    props.onChange = this.handleChange;

    props.options = options;
    if (!this.props.mutable) {
      // props.value = options[0].text;
    } // to show a sample of what tags looks like
    if (this.props.mutable) {
      // props.isDisabled = this.props.read_only;
      props.value = this.state.value;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <Select {...props} />
        </div>
      </div>
    );
  }
}
class SimpleDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  render() {
    const props = {};
    props.className = "form-control";
    props.name = this.props.data.field_name;

    if (this.props.mutable) {
      props.defaultValue = this.props.defaultValue;
      props.ref = this.inputField;
    }

    if (this.props.read_only) {
      props.disabled = "disabled";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <select {...props}>
            {this.props.data.options.map((option) => {
              const this_key = `preview_${option.value}`;
              return (
                <option value={option.value} key={this_key}>
                  {option.text}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    const { defaultValue, data } = props;
    this.state = {
      value: this.getDefaultValue(defaultValue, data.options),
      data: [],
    };
  }

  getDefaultValue(defaultValue, options) {
    if (defaultValue && options) {
      if (typeof defaultValue === "string") {
        const vals = defaultValue.split(",").map((x) => x.trim());
        return options.filter((x) => vals.indexOf(x.value) > -1);
      }
      return options.filter((x) => defaultValue.indexOf(x.value) > -1);
    }
    return 0;
  }
  async getDropdownOptions(url) {
    const response = await fetchWrapper.get(url);
    if (response.isSuccessful) {
      this.setState({ data: response.data, value: this.props.defaultValue });
    }
  }
  // state = { value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [] };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };
  componentDidMount() {
    if (this.props.data.apiUrl) {
      this.getDropdownOptions(this.props.data.apiUrl);
    }
  }
  componentWillReceiveProps(props) {
    if (props.defaultValue !== !this.state.value) {
      this.setState({ value: props.defaultValue });
    }
  }
  render() {
    let options = [];
    if (
      this.props.data.options &&
      (this.props.data.apiUrl === "" || this.props.data.apiUrl === undefined)
    ) {
      options = this.props.data.options.map((option) => {
        option.label = option.text;
        return option;
      });
    } else if (this.state.data) {
      options = this.state.data;
    }
    const props = {};
    props.multiple = true; // this.props.data.isMultiple;
    props.name = this.props.data.field_name;
    props.onChange = this.handleChange;
    props.id = this.props.data.id;
    props.options = options;
    if (!this.props.mutable) {
      // props.value = options[0].text;
    } // to show a sample of what tags looks like
    if (this.props.mutable) {
      // props.isDisabled = this.props.read_only;
      props.value =
        this.state.value === undefined
          ? this.props.defaultValue
          : this.state.value;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";

    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />

          <NativeSelect fullWidth>
            <option value="">select {props.label}</option>
            {props.options &&
              props.options.map((option) => {
                const this_key = `preview_${option.value}`;
                return (
                  <option value={option.value} key={this_key}>
                    {option.text}
                  </option>
                );
              })}
          </NativeSelect>
        </div>
      </div>
    );
  }
}

class DropdownAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    const { defaultValue, data } = props;
    this.state = {
      value: this.getDefaultValue(defaultValue, data.options),
      data: [],
      defaultOptionValue: [],
    };
  }

  getDefaultValue(defaultValue, options) {
    if (defaultValue && options) {
      if (typeof defaultValue === "string") {
        const vals = defaultValue.split(",").map((x) => x.trim());
        return options.filter((x) => vals.indexOf(x.value) > -1);
      }
      return options.filter((x) => defaultValue.indexOf(x.value) > -1);
    } else {
      if (defaultValue === "" || defaultValue === undefined) return "";
      else if (typeof defaultValue === "number") return defaultValue;
      return (
        defaultValue !== null && defaultValue.split(",").map((x) => x.trim())
      );
    }
  }
  async getDropdownOptions(url) {
    const response = await fetchWrapper.get(url);
    if (response.isSuccessful) {
      let defaultOptionValue = [];
      let _defaultValue =
        this.props.defaultValue !== null &&
        this.props.defaultValue !== undefined &&
        this.props.defaultValue !== "" &&
        this.props.defaultValue.split(",").map((x) => parseInt(x.trim()));
      if (_defaultValue.length > 0) {
        defaultOptionValue = response.data.filter((x) =>
          _defaultValue.includes(x.value)
        );
      }
      this.setState({
        data: response.data,
        defaultOptionValue: defaultOptionValue,
      });
    }
  }
  // state = { value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [] };

  handleChange = (e) => {
    let value = Array.prototype.map.call(e, (s) => s.value).toString();
    this.setState({ value: value, defaultOptionValue: e });
  };
  componentDidMount() {
    if (this.props.data.apiUrl) {
      this.getDropdownOptions(this.props.data.apiUrl);
    }
  }
  componentWillReceiveProps(props) {
    if (props.defaultValue !== !this.state.value) {
      let defaultOptionValue = [];

      let _defaultValue =
      this.props.defaultValue !== 0 && this.props.defaultValue !== null &&
        this.props.defaultValue !== undefined &&
        this.props.defaultValue !== "" &&
        this.props.defaultValue.split(",").map((x) => parseInt(x.trim()));
      if (_defaultValue.length > 0) {
        defaultOptionValue = this.state.data.filter((x) =>
          _defaultValue.includes(x.value)
        );
      }
      this.setState({
        value: props.defaultValue,
        defaultOptionValue: defaultOptionValue,
      });
    }
  }
  render() {
    let options = [];
    if (
      this.props.data.options &&
      (this.props.data.apiUrl === "" || this.props.data.apiUrl === undefined)
    ) {
      options = this.props.data.options.map((option) => {
        option.label = option.text;
        return option;
      });
    } else if (this.state.data) {
      options = this.state.data;
    }

    const props = {};
    props.multiple = true; // this.props.data.isMultiple;
    props.name = this.props.data.field_name;
    props.label = this.props.data.label;
    // props.onChange = this.handleChange;
    props.id = this.props.data.id;
    props.options = options;
    if (!this.props.mutable) {
      props.value = options[0].text;
    } // to show a sample of what tags looks like
    if (this.props.mutable) {
      // props.isDisabled = this.props.read_only;
      props.defaultOptionValue = this.state.defaultOptionValue;
      // this.state.value === undefined
      //   ? this.props.defaultValue
      //   : this.state.value;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";

    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }
    const defaultProps = {
      options: options,
      getOptionLabel: (option) => option.text,
    };
    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          {options && (
            <Autocomplete
              {...defaultProps}
              multiple={props.multiple}
              id={props.name}
              // options={options ? options : props.options}
              value={props.defaultOptionValue}
              // defaultValue={props.value}
              // getOptionLabel={(item) => item.text}
              getOptionSelected={(option, value) => option.text === value.text}
              onChange={(event, newValue) => {
                this.handleChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.label}
                  name={props.name}
                  margin="normal"
                  className="mb-3 mt-0"
                  placeholder={props.placeholder}
                />
              )}
            />
          )}

          {/*  <MUISelect fullWidth {...props}>
            {props.options &&
              props.options.map((option) => {
                const this_key = `preview_${option.value}`;
                return (
                  <option value={option.value} key={this_key}>
                    {option.text}
                  </option>
                );
              })}
            </MUISelect> */}
        </div>
      </div>
    );
  }
}

class Checkboxes extends React.Component {
  constructor(props) {
    super(props);
    this.options = {};
  }
  handleChange = (e) => {
    this.setState({ value: parseInt(e.target.value) });
  };
  render() {
    const self = this;
    let classNames = "custom-control custom-checkbox";
    if (this.props.data.inline) {
      classNames += " option-inline";
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel className="form-label" {...this.props} />
          {this.props.data.options &&
            this.props.data.options.map((option) => {
              const this_key = `preview_${option.value}`;
              const props = {};
              props.name = `option_${option.value}`;
              props.onChange = this.handleChange;
              props.type = "checkbox";
              props.value = option.value;
              if (self.props.mutable) {
                props.defaultChecked =
                  self.props.defaultValue !== undefined &&
                  self.props.defaultValue.indexOf(option.value) > -1;
              }
              if (this.props.read_only) {
                props.disabled = "disabled";
              }
              return (
                <div className={classNames} key={this_key}>
                  <input
                    id={"fid_" + this_key}
                    className="custom-control-input"
                    ref={(c) => {
                      if (c && self.props.mutable) {
                        self.options[`child_ref_${option.value}`] = c;
                      }
                    }}
                    {...props}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={"fid_" + this_key}
                  >
                    {option.text}
                  </label>
                </div>
              );
            })}
        </div>
      </div>
    );
  }
}

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.options = {};
    this.state = {
      data: [],
      defaultOptionValue: [],
    };
  }

  async getDropdownOptions(url) {
    const response = await fetchWrapper.get(url);
    if (response.isSuccessful) {
      this.setState({ data: response.data, value: this.props.defaultValue });
    }
  }
  // state = { value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [] };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };
  componentDidMount() {
    if (this.props.data.apiUrl) {
      this.getDropdownOptions(this.props.data.apiUrl);
    }
  }
  handleChange = (e) => {
    this.setState({ value: parseInt(e.target.value) });
  };
  render() {
    let options = [];
    if (
      this.props.data.options &&
      (this.props.data.apiUrl === "" || this.props.data.apiUrl === undefined)
    ) {
      options = this.props.data.options.map((option) => {
        option.label = option.text;
        return option;
      });
    } else if (this.state.data) {
      options = this.state.data;
    }
    const self = this;
    let classNames = "custom-control custom-radio";
    if (this.props.data.classNames) {
      classNames += this.props.data.classNames;
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += this.props.data.baseClasses;
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel className="form-label" {...this.props} />
          {options.map((option) => {
            const this_key = `preview_${option.value}`;
            const props = {};
            props.name = self.props.data.field_name;
            props.onChange = this.handleChange;
            props.type = "radio";
            props.value = option.value;
            props.defaultChecked = false;
            if (self.props.mutable) {
              props.defaultChecked =
                self.props.defaultValue !== undefined &&
                self.props.defaultValue === option.value;
              /*  self.props.defaultValue.indexOf(option.value) > -1 ||
                  self.props.defaultValue.indexOf(option.value) > -1) */
            }
            if (this.props.read_only) {
              props.disabled = "disabled";
            }

            return (
              <div className={classNames} key={this_key}>
                <input
                  id={"fid_" + this_key}
                  className="custom-control-input"
                  ref={(c) => {
                    if (c && self.props.mutable) {
                      self.options[`child_ref_${option.value}`] = c;
                    }
                  }}
                  {...props}
                />
                <label
                  className="custom-control-label"
                  htmlFor={"fid_" + this_key}
                >
                  {option.text}
                </label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

class Image extends React.Component {
  render() {
    const style = this.props.data.center ? { textAlign: "center" } : null;

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses} style={style}>
        {!this.props.mutable && (
          <HeaderBar
            parent={this.props.parent}
            editModeOn={this.props.editModeOn}
            data={this.props.data}
            onDestroy={this.props._onDestroy}
            onEdit={this.props.onEdit}
            required={this.props.data.required}
          />
        )}
        {this.props.data.src && (
          <img
            src={this.props.data.src}
            width={this.props.data.width}
            height={this.props.data.height}
            alt=""
          />
        )}
        {!this.props.data.src && <div className="no-image">No Image</div>}
      </div>
    );
  }
}

class Rating extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
  }

  render() {
    const props = {};
    props.name = this.props.data.field_name;
    props.ratingAmount = 5;

    if (this.props.mutable) {
      props.rating =
        this.props.defaultValue !== undefined
          ? parseFloat(this.props.defaultValue, 10)
          : 0;
      props.editing = true;
      props.disabled = this.props.read_only;
      props.ref = this.inputField;
    }

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <StarRating {...props} />
        </div>
      </div>
    );
  }
}

class HyperLink extends React.Component {
  render() {
    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <a
            target="_blank"
            href={this.props.data.href}
            rel="noopener noreferrer"
          >
            {this.props.data.content}
          </a>
        </div>
      </div>
    );
  }
}

class Download extends React.Component {
  render() {
    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <a
            href={`${this.props.download_path}?id=${this.props.data.file_path}`}
          >
            {this.props.data.content}
          </a>
        </div>
      </div>
    );
  }
}

class Camera extends React.Component {
  constructor(props) {
    super(props);
    this.state = { images: [], value: "", errorMessage: "", showError: false };
  }
  async fileUpload(params) {
    //pass the parameter of filereferece code for multiple file upload
    let url = "FileAttach?FileReferenceCode=" + this.state.value;

    const response = await fetchWrapper.postFile(url, params);
    if (response.isSuccessful) {
      if (response.data && response.data.length) {
        // Create a new array based on current state:
        let images = [...this.state.images];

        // Add item to it
        images.push(response.data[0]);
        //set images and file Reference Code into current state
        this.setState({
          images: images,
          value: response.data[0].fileReferenceCode,
        });
      }
    }
  }
  async deleteFile(fileAttachmentId) {
    let url = "File/" + fileAttachmentId;

    const response = await fetchWrapper.delete(url);
    if (response.isSuccessful) {
      if (response.data) {
        const listFile = this.state.images.filter(
          (item) => item.fileAttachmentId !== fileAttachmentId
        );
        this.setState({ images: listFile });
      }
    }
  }
  async getImages(fileReferenceCode) {
    let url = "dynamic/files/" + fileReferenceCode;
    const response = await fetchWrapper.get(url);
    if (response.isSuccessful) {
      if (response.data && response.data.length) {
        this.setState({ images: response.data });
      }
    }
  }
  displayImage = (e) => {
    // const self = this;
    const target = e.target;

    let reader = new FileReader();
    let totalFileSeleted = target.files.length + this.state.images.length;
    let maxFile = this.props.maxFile;
    if (totalFileSeleted > maxFile) {
      this.setState({
        errorMessage: `${maxFile} files only allow`,
        showError: true,
      });
      return;
    }
    if (target.files && target.files.length) {
      for (let i = 0; i < target.files.length; i++) {
        let file = target.files[i];
        // eslint-disable-next-line no-undef
        // reader = new FileReader();
        // reader.readAsDataURL(file);
        reader.onloadend = () => {
          const formData = new FormData();
          formData.append("image", file);
          this.fileUpload(formData);
        };
        reader.readAsDataURL(file);
      }
    }
  };
  componentDidMount() {
    this.setState({ value: this.props.defaultValue });
    this.getImages(this.props.defaultValue);
  }
  clearImage = (fileAttachmentId) => {
    this.deleteFile(fileAttachmentId);
  };

  render() {
    let baseClasses = "SortableItem rfb-item";
    const name = this.props.data.field_name;
    const fileInputStyle = this.state.img ? { display: "none" } : null;
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }
    let sourceDataURL;
    if (
      this.props.read_only === true &&
      this.props.defaultValue &&
      this.props.defaultValue.length > 0
    ) {
      if (this.props.defaultValue.indexOf(name > -1)) {
        sourceDataURL = this.props.defaultValue;
      } else {
        sourceDataURL = `data:image/png;base64,${this.props.defaultValue}`;
      }
    }
    const { errorMessage, showError } = this.state;

    return (
      <div className={baseClasses}>
        {this.props.data && <ComponentHeader {...this.props} />}

        <div className="form-group">
          {this.props.data && <ComponentLabel {...this.props} />}
          {this.props.read_only === true &&
          this.props.defaultValue &&
          this.props.defaultValue.length > 0 ? (
            <div>
              <img src={sourceDataURL} alt=".." />
            </div>
          ) : (
            <div className="row image-upload-container ">
              <div style={fileInputStyle} className="col-12">
                <input
                  name={name}
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="image-upload"
                  onChange={this.displayImage}
                />
                <div className="image-upload-control">
                  <div className="btn btn-default btn-school">
                    <i className="zmdi zmdi-camera mr-2"></i> Upload Photo
                  </div>
                  {showError === true && (
                    <div className="display-4 font-weight-bold invalid-feedback text-danger">
                      {errorMessage}
                    </div>
                  )}
                  <div>Select an image from your computer or device.</div>
                </div>
              </div>
              <div className="col-12" style={{ display: "flex" }}>
                {this.state.images &&
                  this.state.images.map((item, index) => (
                    <div style={{ padding: "10px" }}>
                      <img
                        src={item.fileAttachmentPath}
                        height="100"
                        className="image-upload-preview"
                        alt=".."
                      />
                      <br />
                      <div
                        className="btn btn-primary"
                        onClick={() => this.clearImage(item.fileAttachmentId)}
                      >
                        <i className="zmdi zmdi-close"></i> Remove
                      </div>
                    </div>
                  ))}
              </div>
              {/* this.state.img && (
                <div>
                  <img
                    src={this.state.img}
                    height="100"
                    className="image-upload-preview"
                  />
                  <br />
                  <div
                    className="btn btn-school btn-image-clear"
                    onClick={this.clearImage}
                  >
                    <i className="zmdi zmdi-close"></i> Clear Photo
                  </div>
                </div>
              ) */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Camera.defaultProps = {
  alt: "...",
  maxFile: 1,
};

Camera.propTypes = {
  /**
   * External classes
   */
  maxfile: PropTypes.number,
  // /**
  //  * Source of the image
  //  */
  // src: PropTypes.string.isRequired,
  // /**
  //  * Source set for the responsive images
  //  */
  // srcSet: PropTypes.string,
  // /**
  //  * Image title
  //  */
  // alt: PropTypes.string,
  // /**
  //  * Should lazy load the image
  //  */
  // lazy: PropTypes.bool,
};

class FileDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = { images: [], value: "", errorMessage: "", showError: false };
  }

  async getImages(fileReferenceCode) {
    let url = "dynamic/files/" + fileReferenceCode;
    const response = await fetchWrapper.get(url);
    if (response.isSuccessful) {
      if (response.data && response.data.length) {
        this.setState({ images: response.data });
      }
    }
  }

  componentDidMount() {
    this.getImages(this.props.defaultValue);
  }

  render() {
    let baseClasses = "SortableItem rfb-item";

    return (
      <div className={baseClasses}>
        <div className="row image-upload-container ">
          <div className="col-12" style={{ display: "flex" }}>
            {this.state.images &&
              this.state.images.map((item, index) => (
                <div style={{ padding: "10px" }}>
                  <img
                    src={item.fileAttachmentPath}
                    height="100"
                    className="image-upload-preview"
                    alt=".."
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

FileDisplay.defaultProps = {
  alt: "...",
  maxFile: 1,
  defaultValue: "",
};

FileDisplay.propTypes = {
  /**
   * External classes
   */
  maxfile: PropTypes.number,
  defaultValue: PropTypes.string.isRequired,
  // /**
  //  * Source of the image
  //  */
  // src: PropTypes.string.isRequired,
  // /**
  //  * Source set for the responsive images
  //  */
  // srcSet: PropTypes.string,
  // /**
  //  * Image title
  //  */
  // alt: PropTypes.string,
  // /**
  //  * Should lazy load the image
  //  */
  // lazy: PropTypes.bool,
};

/* 
class Range extends React.Component {
  constructor(props) {
    super(props);
    this.inputField = React.createRef();
    this.state = {
      value:
        props.defaultValue !== undefined
          ? parseInt(props.defaultValue, 10)
          : parseInt(props.data.default_value, 10),
    };
  }

  changeValue = (e) => {
    const { target } = e;
    this.setState({
      value: target.value,
    });
  };

  render() {
    const props = {};
    const name = this.props.data.field_name;

    props.type = "range";
    props.list = `tickmarks_${name}`;
    props.min = this.props.data.min_value;
    props.max = this.props.data.max_value;
    props.step = this.props.data.step;

    props.value = this.state.value;
    props.change = this.changeValue;

    if (this.props.mutable) {
      props.ref = this.inputField;
    }

    const datalist = [];
    for (
      let i = parseInt(props.min_value, 10);
      i <= parseInt(props.max_value, 10);
      i += parseInt(props.step, 10)
    ) {
      datalist.push(i);
    }

    const oneBig = 100 / (datalist.length - 1);

    const _datalist = datalist.map((d, idx) => (
      <option key={`${props.list}_${idx}`}>{d}</option>
    ));

    const visible_marks = datalist.map((d, idx) => {
      const option_props = {};
      let w = oneBig;
      if (idx === 0 || idx === datalist.length - 1) {
        w = oneBig / 2;
      }
      option_props.key = `${props.list}_label_${idx}`;
      option_props.style = { width: `${w}%` };
      if (idx === datalist.length - 1) {
        option_props.style = { width: `${w}%`, textAlign: "right" };
      }
      return <label {...option_props}>{d}</label>;
    });

    let baseClasses = "SortableItem rfb-item";
    if (this.props.data.pageBreakBefore) {
      baseClasses += " alwaysbreak";
    }

    return (
      <div className={baseClasses}>
        <ComponentHeader {...this.props} />
        <div className="form-group">
          <ComponentLabel {...this.props} />
          <div className="range">
            <div className="clearfix">
              <span className="float-left">{this.props.data.min_label}</span>
              <span className="float-right">{this.props.data.max_label}</span>
            </div>
            <ReactBootstrapSlider {...props} />
          </div>
          <div className="visible_marks">{visible_marks}</div>
          <input name={name} value={this.state.value} type="hidden" />
          <datalist id={props.list}>{_datalist}</datalist>
        </div>
      </div>
    );
  }
} */

FormElements.Header = Header;
FormElements.Paragraph = Paragraph;
FormElements.Label = Label;
FormElements.LineBreak = LineBreak;
FormElements.TextInput = TextInput;
FormElements.NumberInput = NumberInput;
FormElements.TextArea = TextArea;
FormElements.Dropdown = Dropdown;
FormElements.SimpleDropdown = SimpleDropdown;
FormElements.DropdownAutoComplete = DropdownAutoComplete;

// FormElements.Signature = Signature;
FormElements.Checkboxes = Checkboxes;
FormElements.DatePicker = DatePicker;
FormElements.RadioButtons = RadioButtons;
FormElements.Image = Image;
FormElements.Rating = Rating;
FormElements.Tags = Tags;
FormElements.HyperLink = HyperLink;
FormElements.Download = Download;
FormElements.Camera = Camera;
FormElements.FileDisplay = FileDisplay;
// FormElements.Range = Range;

export default FormElements;
