import axios from "axios";
import AppConfig from "./AppConfig";
import { setupCache } from "axios-cache-adapter";
import * as loghelper from "./loghelper";

const cache = setupCache({
  maxAge: 15 * 60 * 60 * 1000,
  exclude: {
    paths: [],
  },
});

const api = axios.create({
  adapter: cache.adapter,
});

api.interceptors.request.use((request) => {
  if (AppConfig.axiosLogger) {
    loghelper.consoleTime("ajax " + request.method);
  }
  return request;
});

api.interceptors.response.use((response) => {
  if (AppConfig.axiosLogger) {
    loghelper.consoleTimeEnd("ajax " + response.config.method);
  }
  return response;
});

export const getAxios = (url, params) => {
  let headers = {
    authorization:
      "bearer " +
      (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  url = AppConfig.env("url") + url;
  var resp = axios({
    method: "get",
    url: url,
    headers: headers,
    data: params ? params : "",
  })
    .then(function(response) {
      return response;
    })
    .catch(function(response) {
      return response;
    });
  return resp;
};

export const postAxios = (url, params) => {
  let headers = {
    authorization:
      "bearer " +
      (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
    "Access-Control-Allow-Origin": "*",
  };
  url = AppConfig.env("url") + url;

  var resp = axios({
    method: "post",
    url: url,
    data: params,
    headers: headers,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(response) {
      return response;
    });
  return resp;
};

export const putAxios = (url, params) => {
  let headers = {
    authorization:
      "bearer " +
      (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
    "Access-Control-Allow-Origin": "*",
  };
  url = AppConfig.env("url") + url;

  var resp = axios({
    method: "put",
    url: url,
    data: params,
    headers: headers,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(response) {
      return response;
    });
  return resp;
};

export const deleteAxios = (url, params) => {
  let headers = {
    authorization:
      "bearer " +
      (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
    "Access-Control-Allow-Origin": "*",
  };

  url = AppConfig.env("url") + url;

  var resp = axios({
    method: "delete",
    url: url,
    data: params ? params : "",
    headers: headers,
  })
    .then(function(response) {
      return response;
    })
    .catch(function(response) {
      return response;
    });
  return resp;
};

export const postAxiosWithFile = (url, params) => {
  let token = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : "";
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: `bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
    },
  };

  url = AppConfig.env("url") + url;
  var resp = axios
    .post(url, params, config)
    .then(function(response) {
      return response;
    })
    .catch(function(response) {
      return response;
    });
  return resp;
};

export const getCookie = (c_name) => {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + "=");
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) {
        c_end = document.cookie.length;
      }
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
};
