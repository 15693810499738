import React from "react";
import { Paper } from "@material-ui/core";
import ReactFormBuilder from "./FormBuilder";
import DemoBar from "./DemoBar";
import fetchWrapper from "util/fetch-wrapper";
import { PAGE } from "services/APIUrl/Api";
import store from "components/FormBuilder/data/store";
// const url = "/api/formdata";
// const saveUrl = "/api/formdata";

const FormGenBuilder = () => {
  // const refDropdown = useRef();
  const [data] = React.useState(null);
  const handleSubmit = (data) => {
    // console.log("asdas", data);
  };
  const handleChange = (value) => {
    let pageConfigurationJson = [];
    if (value) {
      fetchWrapper.get(PAGE + "/" + value).then((res) => {
        if (res.isSuccessful) {
          if (res.data.pageConfigurationJson)
            store.dispatch(
              "updateOrder",
              JSON.parse(res.data.pageConfigurationJson)
            );
        }
      });
    } else store.dispatch("updateOrder", pageConfigurationJson);
  };

  return (
    <Paper>
      <DemoBar onChange={handleChange} />
      <ReactFormBuilder.ReactFormBuilder
        onSubmit={handleSubmit}
        data={data}
        // variables={variables}
        // url={url}
        // saveUrl={saveUrl}
      />
    </Paper>
  );
};

export default FormGenBuilder;
