import React from "react";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
const NavItem = (props) => {
  const { icon, url, languageKey, children } = props.item;
  return (
    <React.Fragment>
      {children && children.length > 0 ? (
        <li className="menu collapse-box">
          <Button>
            <i className={icon} />
            <span className="nav-text">
              <IntlMessages id={languageKey} />
            </span>
          </Button>
          <ul className="sub-menu">
            {children &&
              children.map((item, index) => (
                <li key={index}>
                  <NavLink
                    className="prepend-icon"
                    to={`${item.url}-${item.id}`}
                  >
                    <span className="nav-text text-transform-none">
                      <IntlMessages id={item.languageKey} />
                    </span>
                  </NavLink>
                </li>
              ))}
          </ul>
        </li>
      ) : (
        <li className="menu  no-arrow">
          <NavLink to={`${url}`}>
            <i className={icon} />
            <span className="nav-text">
              <IntlMessages id={languageKey} />
            </span>
          </NavLink>
        </li>
      )}
    </React.Fragment>
  );
};

export default NavItem;
