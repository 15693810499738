/**
 * <ReactFormBuilder />
 */

import React from "react";
// import ReactDOM from "react-dom";

import Preview from "components/FormBuilder/preview";
import Toolbar from "components/FormBuilder/toolbar";
import ReactFormGenerator from "components/FormBuilder/form";
import store from "components/FormBuilder/data/store";

// import { DndProvider } from "react-dnd";
// import HTML5Backend from "react-dnd-html5-backend";

class ReactFormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      editElement: null,
      data: this.props.data,
      /*[ {
          id: "643C9F5A",
          element: "Dropdown",
          text: "Dropdown",
          required: true,
          canHaveAnswer: true,
          canHavePageBreakBefore: true,
          canHaveAlternateForm: true,
          canHaveDisplayHorizontal: true,
          canHaveOptionCorrect: true,
          canHaveOptionValue: true,
          canPopulateFromApi: true,
          apiUrl: "commonmaster/menu",
          field_name: "menuId",
          label: "Select Page",
        }, ], */
    };

    this.editModeOn = this.editModeOn.bind(this);
  }

  editModeOn(data, e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.state.editMode) {
      this.setState({ editMode: !this.state.editMode, editElement: null });
    } else {
      this.setState({ editMode: !this.state.editMode, editElement: data });
    }
  }

  manualEditModeOff() {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        editElement: null,
      });
    }
  }

  render() {
    const toolbarProps = {
      showDescription: this.props.show_description,
    };
    if (this.props.toolbarItems) {
      toolbarProps.items = this.props.toolbarItems;
    }
    return (
      <div>
        {/* <div>
           <p>
             It is easy to implement a sortable interface with React DnD. Just make
             the same component both a drag source and a drop target, and reorder
             the data in the <code>hover</code> handler.
           </p>
           <Container />
         </div> */}
        <div className="react-form-builder clearfix">
          <div>
            <Preview
              files={this.props.files}
              manualEditModeOff={this.manualEditModeOff.bind(this)}
              showCorrectColumn={this.props.showCorrectColumn}
              parent={this}
              data={this.state.data}
              url={this.props.url}
              saveUrl={this.props.saveUrl}
              onLoad={this.props.onLoad}
              onPost={this.props.onPost}
              editModeOn={this.editModeOn}
              editMode={this.state.editMode}
              variables={this.props.variables}
              editElement={this.state.editElement}
            />
            <Toolbar {...toolbarProps} />
          </div>
        </div>
      </div>
    );
  }
}

const FormBuilders = {};
FormBuilders.ReactFormBuilder = ReactFormBuilder;
FormBuilders.ReactFormGenerator = ReactFormGenerator;
FormBuilders.ElementStore = store;

export default FormBuilders;

export { ReactFormBuilder, ReactFormGenerator, store as ElementStore };
