import { OrderActionTypes } from "constants/ActionTypes";

const INIT_STATE = {
  loader: true,
  noContentFoundMessage: "No contact found in selected folder",
  alertMessage: "",
  showMessage: false,
  orderUpdateState: false,
  orderList: [],
  selectedOrders: 0,
  pageConfiguration: {},
  orderDetail: {},
  filterToggle: false,
  id: [],
  orderFilter: {
    pageSize: 5,
    pageIndex: 0,
    SortingOrderBy: "",
    sortDir: "asc",
    loading: true,
    sectionId: 0,
    selected: [],
    totalRecords: 0,
    filterValue: [],
  },
  filterValue: [],
};

const orderReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case OrderActionTypes.FETCH_PAGE_CONFIGURATION_START:
      return {
        ...state,
        loader: true,
      };
    case OrderActionTypes.FETCH_PAGE_CONFIGURATION_SUCCESS:
      return {
        ...state,
        loader: false,
        pageConfiguration: action.payload.data,
        orderFilter: {
          ...state.orderFilter,
          sectionId: action.payload.data.sections.section[0].sectionId,
          loading: false,
        },
      };
    case OrderActionTypes.FETCH_ALL_ORDER: {
      console.log(action.payload);
      return {
        ...state,
        loader: true,
        orderFilter: action.payload,
      };
    }
    case OrderActionTypes.FETCH_ALL_ORDER_SUCCESS: {
      return {
        ...state,
        loader: false,
        orderFilter: {
          ...state.orderFilter,
          loading: false,
          totalRecords: action.payload.totalRecords,
          selected: [],
        },
        orderList: action.payload.data,
      };
    }
    case OrderActionTypes.ON_ORDER_GRID_PAGE_CHANGE:
      return {
        ...state,
        loader: false,
        orderFilter: {
          ...state.orderFilter,
          pageIndex: parseInt(action.payload, 10),
        },
      };
    case OrderActionTypes.ON_ORDER_GRID_PAGESIZE_CHANGE:
      return {
        ...state,
        loader: false,
        orderFilter: {
          ...state.orderFilter,
          pageSize: parseInt(action.payload),
        },
      };

    case OrderActionTypes.ON_ORDER_GRID_ROW_SELECT:
      return {
        ...state,
        id: action.payload.id,
        orderDetail: {},
      };
    case OrderActionTypes.ON_ORDER_GRID_ROW_DESELECT:
      return {
        ...state,
        id: [],
      };

    case OrderActionTypes.ON_ORDER_GRID_COLMN_SORT:
      return {
        ...state,
        loader: false,
        orderFilter: {
          ...state.orderFilter,
          loading: true,
          sortDir: action.payload.isAsc ? "asc" : "desc",
          SortingOrderBy: action.payload.property,
        },
      };

    case OrderActionTypes.ON_ORDER_GRID_FILTERCHANGE: {
      let newValue = { key: action.payload.name, value: action.payload.value };
      if (
        state.filterValue.length > 0 &&
        state.filterValue.filter((x) => x.key === newValue.key).length > 0
      ) {
        let newMarkers = state.filterValue.map((el) =>
          el.key === newValue.key ? { ...el, value: newValue.value } : el
        );
        return {
          ...state,
          filterValue: newMarkers,
        };
      } else {
        return {
          ...state,
          orderFilter: {
            ...state.orderFilter,
            filterValue: [...state.filterValue, newValue],
          },
        };
      }
    }
    case OrderActionTypes.ON_ORDER_UPDATE:
      return {
        ...state,
        loader: true,
      };
    case OrderActionTypes.ON_ORDER_UPDATE_SUCCESS:
      return {
        ...state,
        alertMessage: "Order Updated Successfully",
        showMessage: true,
      };

    case OrderActionTypes.ON_ORDER_DELETE:
      return {
        ...state,
        loader: true,
      };
    case OrderActionTypes.ON_ORDER_VIEW_DETAIL:
      return {
        ...state,
        orderUpdateState: false,
      };
    case OrderActionTypes.ON_ORDER_VIEW_DETAIL_SUCCESS:
      return {
        ...state,
        loader: false,
        orderDetail: action.payload.data,
        orderUpdateState: true,
      };
    case OrderActionTypes.ON_ORDER_VIEW_CLOSE:
      return {
        ...state,
        loader: false,
        orderUpdateState: false,
        showMessage: false,
        id: [],
      };
    case OrderActionTypes.ON_HIDE_LOADER: {
      return { ...state, loader: false };
    }
    case OrderActionTypes.SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }

    default:
      return state;
  }
};

export default orderReducer;
