import axios from "axios";
import { getAxios } from "../util/axios-utils";
import { LOGIN_USER_DETAIL } from "../services/APIUrl/Api";
import AppConfig from "../util/AppConfig";
export const userToken = async (UserName, Password, provider) => {
  let params =
    "username=" +
    UserName +
    "&password=" +
    Password +
    "&grant_type=password" +
    "&provider=" +
    provider;
  let url = AppConfig.env("baseUrl") + "/token";
  const response = await axios({
    method: "post",
    url: url,
    data: params,
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  }).catch((response) => {
    return response.response.data;
  });

  if (response.hasOwnProperty("error")) {
    return response;
  } else {
    localStorage.setItem("currentUser", JSON.stringify(response.data));
    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("tokenType", response.data.token_type);
    localStorage.setItem("userId", response.data.userId);
    return response.data;
  }
};
export const LogOutUser = () => {
  localStorage.clear();
  sessionStorage.clear();
};
export const getUser = async () => {
  let userapiurl =
    LOGIN_USER_DETAIL +
    "/" +
    (localStorage.getItem("userId")
      ? parseInt(localStorage.getItem("userId"))
      : "");
  const response = await getAxios(userapiurl);

  if (response.data.data.length > 0)
    localStorage.setItem("currentUser", JSON.stringify(response.data.data[0]));
  return response.data.data;
};

export const getCurrentUser = () => {
  let token = localStorage.getItem("currentUser");
  if (token !== null) {
    return JSON.parse(token);
  } else {
    return null;
  }
};

// export const user_sign_up = async (userdata) => {
//   const response = await postAxios(SIGNUP_BUSINESS, userdata);
//   if (response.data.data.length > 0)
//     localStorage.setItem("userId", parseInt(response.data.data));
//   return response.data;
// };
