import React from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { COMMON_MASTER, APP_DROPDOWN_KEY } from "services/APIUrl";
import { FormControl, FormControlLabel, Switch } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useForm, Controller } from "react-hook-form";
import MuiAutoComplete from "components/atoms/MuiAutoComplete/MuiAutoComplete";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn,
} from "actions/Auth";
import fetchWrapper from "util/fetch-wrapper";
import { SIGNUP_BUSINESS } from "../services/APIUrl/Api";

const SignUp = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { handleSubmit, control, errors: fieldsErrors } = useForm();

  const onSubmitSignUp = async (data) => {
    const response = await fetchWrapper.post(SIGNUP_BUSINESS, data);

    if (response.isSuccessful) {
      props.history.push("/signup-success", { id: response.data });
      enqueueSnackbar("successfully signup", {
        variant: "success",
      });
    } else {
      enqueueSnackbar(response.data, {
        variant: "error",
      });
    }
  };
  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="favsha">
            <img
              src={require("assets/images/logo.png")}
              alt="favsha"
              title="favsha"
              style={{ width: "177px" }}
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header">
            <h1>
              {" "}
              <IntlMessages id="appModule.signUp.createAccount" />
            </h1>
          </div>

          <div className="app-login-form">
            <form onSubmit={handleSubmit(onSubmitSignUp)}>
              <FormControl fullWidth margin="normal" className="mt-0 mb-2">
                <Controller
                  name="name"
                  defaultValue=""
                  as={
                    <TextField
                      id="name"
                      type="name"
                      helperText={
                        fieldsErrors.name ? fieldsErrors.name.message : null
                      }
                      label={<IntlMessages id="appModule.signUp.name" />}
                      error={fieldsErrors.name}
                    />
                  }
                  control={control}
                  rules={{
                    required: "is required",
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal" className="mt-0 mb-2">
                <Controller
                  name="emailId"
                  defaultValue=""
                  as={
                    <TextField
                      id="emailId"
                      helperText={
                        fieldsErrors.emailId
                          ? fieldsErrors.emailId.message
                          : null
                      }
                      label={<IntlMessages id="appModule.signUp.emailId" />}
                      error={fieldsErrors.emailId}
                    />
                  }
                  control={control}
                  rules={{
                    required: "Required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "invalid email address",
                    },
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal" className="mt-0 mb-2">
                <Controller
                  name="password"
                  as={
                    <TextField
                      id="password"
                      type="password"
                      helperText={
                        fieldsErrors.password
                          ? fieldsErrors.password.message
                          : null
                      }
                      label={<IntlMessages id="appModule.signUp.password" />}
                      error={fieldsErrors.password}
                    />
                  }
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "Required",
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="normal" className="mt-0 mb-2">
                <Controller
                  name="businessName"
                  defaultValue=""
                  as={
                    <TextField
                      id="businessName"
                      helperText={
                        fieldsErrors.businessName
                          ? fieldsErrors.businessName.message
                          : null
                      }
                      label={<IntlMessages id="appModule.signUp.compnayName" />}
                      error={fieldsErrors.businessName}
                    />
                  }
                  control={control}
                  rules={{
                    required: "Required",
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="normal" className="mt-0 mb-2">
                <Controller
                  name="phoneNo"
                  as={
                    <TextField
                      id="phoneNo"
                      helperText={
                        fieldsErrors.phoneNo
                          ? fieldsErrors.phoneNo.message
                          : null
                      }
                      label={<IntlMessages id="appModule.signUp.phoneNo" />}
                      error={fieldsErrors.phoneNo}
                    />
                  }
                  control={control}
                  rules={{
                    required: "Required",
                  }}
                  defaultValue=""
                />
              </FormControl>

              <MuiAutoComplete
                control={control}
                url={COMMON_MASTER + APP_DROPDOWN_KEY.category}
                label={<IntlMessages id="appModule.signUp.category" />}
                name="categoryId"
                id="categoryId"
                defaultValue="1"
              />

              <div className="form-group d-flex justify-content-between align-items-center">
                <FormControlLabel
                  control={
                    <Controller
                      as={Switch}
                      name="isAgree"
                      type="checkbox"
                      control={control}
                      defaultValue={true}
                    />
                  }
                  rules={{
                    required: "Required",
                  }}
                  label="I agree to the "
                />
                <div>
                  <Link to="#" title="Privacy Policy">
                    <IntlMessages id="appModule.signUp.Privacy" />
                  </Link>{" "}
                  <Link to="#" title="Privacy Policy">
                    <IntlMessages id="appModule.signUp.websiteterms" />
                  </Link>
                </div>
              </div>

              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button variant="contained" type="submit" color="primary">
                  <IntlMessages id="appModule.regsiter" />
                </Button>
                <Link to="/signin">
                  <IntlMessages id="signUp.alreadyMember" />
                </Link>
              </div>
              {/* <div className="app-social-block my-1 my-sm-3">
                <IntlMessages id="signIn.connectWith" />
                <ul className="social-link">
                  <li>
                    <IconButton
                      className="icon"
                      onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userFacebookSignIn();
                      }}
                    >
                      <i className="zmdi zmdi-facebook" />
                    </IconButton>
                  </li>

                  <li>
                    <IconButton
                      className="icon"
                      onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userTwitterSignIn();
                      }}
                    >
                      <i className="zmdi zmdi-twitter" />
                    </IconButton>
                  </li>

                  <li>
                    <IconButton
                      className="icon"
                      onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userGoogleSignIn();
                      }}
                    >
                      <i className="zmdi zmdi-google-plus" />
                    </IconButton>
                  </li>

                  <li>
                    <IconButton
                      className="icon"
                      onClick={() => {
                        // this.props.showAuthLoader();
                        // this.props.userGithubSignIn();
                      }}
                    >
                      <i className="zmdi zmdi-github" />
                    </IconButton>
                  </li>
                </ul>
                    </div> */}
            </form>
          </div>
        </div>
      </div>

      {props.loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {/* props.showUserSuccessMessage &&
        NotificationManager.success(props.alertMessage) */}
      {/* showMessage && NotificationManager.error(alertMessage)
      <NotificationContainer /> */}
    </div>
  );
};

const mapStateToProps = ({ auth }) => {
  const {
    loader,
    alertMessage,
    showMessage,
    authUser,
    showUserSuccessMessage,
  } = auth;
  return {
    loader,
    alertMessage,
    showMessage,
    authUser,
    showUserSuccessMessage,
  };
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
})(SignUp);
