import React, { useEffect } from "react";
import { Modal, ModalHeader } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import IntlMessages from "util/IntlMessages";
// import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Input } from "@material-ui/core";
import validate from "validate.js";
import { ItemSaveUpdate } from "services/Common";
import { STAFF, CUSTOMER, OUTLET } from "services/APIUrl";
import { File, ImageUploadInput } from "components/atoms";

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 150,
    },
  },
  lastName: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 150,
    },
  },
  emailId: {
    presence: { allowEmpty: false, message: "is required" },
  },
  phoneNo: {
    presence: { allowEmpty: false, message: "is required" },
  },
};

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(3),
//   },
//   button: {
//     margin: theme.spacing(1, 1, 0, 0),
//   },
// }));
const AddContact = (props) => {
  // const classes = useStyles();
  const { onSaveContact, onContactClose, open, contact, dropdown } = props;
  const [fileReferenceCode, setFileReferenceCode] = React.useState("");
  const [userType, setUserType] = React.useState("");
  const [formState, setFormState] = React.useState({
    isValid: false,
    values: props.contact,
    touched: {},
    errors: {},
  });
  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
    UserTypehandleChange(props.contact.userType);
  }, [formState.values, props]);

  const handleChange = (event) => {
    event.persist();

    // const { name, value } = event.target;
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === "checkbox"
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    setFormState((formState) => ({
      ...formState,
      touched: {
        ...formState.touched,
        ...formState.errors,
      },
    }));
    if (formState.isValid) {
      let params = { url: userType, data: formState.values, id: contact.id };
      ItemSaveUpdate(params).then((result) => {
        if (result.isSuccessful) {
          setFormState((formState) => ({
            ...formState,
            values: {},
            touched: {
              ...formState.touched,
              ...formState.errors,
            },
          }));
          setFileReferenceCode("");
          onSaveContact(formState.values);
          onContactClose();
        }
      });
    }
  };
  const UserTypehandleChange = (value) => {
    if (value === 3) {
      setUserType(CUSTOMER);
    } else if (value === 6) {
      setUserType(STAFF);
    } else if (value === 5) {
      setUserType(OUTLET);
    }
  };

  const handleChangeFormState = (key, value) => {
    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [key]: value,
      },
      touched: {
        ...formState.touched,
        [key]: true,
      },
    }));
  };
  const onFileChange = (value) => {
    setFileReferenceCode(value);
    handleChangeFormState("profilePhoto", value);
  };
  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  let { thumb } = props;
  if (!thumb) {
    thumb = "https://via.placeholder.com/225x225";

    return (
      <Modal className="modal-box" toggle={onContactClose} isOpen={open}>
        <ModalHeader className="modal-box-header bg-primary text-white">
          {contact && contact.name === "" ? (
            <IntlMessages id="contact.addContact" />
          ) : (
            <IntlMessages id="contact.saveContact" />
          )}
          <IconButton className="text-white" onClick={onContactClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>

        <div className="modal-box-content">
          <div className="row no-gutters">
            <div className="col-lg-3 text-center text-lg-right order-lg-2">
              <ImageUploadInput
                onFileChange={onFileChange}
                fileReferenceCode={fileReferenceCode || ""}
              />
              <File fileReferenceCode={fileReferenceCode || ""} />
            </div>

            <div className="col-lg-9 d-flex flex-column order-lg-1">
              <FormControl className="col-xl-12 col-lg-12 col-md-12">
                <InputLabel id="productType-label">
                  <IntlMessages id="contact.userType" />
                </InputLabel>
                <Select
                  labelId="userType-label"
                  id="userType"
                  variant="outlined"
                  fullWidth
                  label={<IntlMessages id="contact.userType" />}
                  name="userType"
                  value={formState.values.userType || ""}
                  onChange={(event) => {
                    handleChange(event);
                    UserTypehandleChange(event.target.value);
                  }}
                  input={<Input id="userType" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dropdown &&
                    dropdown.usertype &&
                    dropdown.usertype.map((item, i) => (
                      <MenuItem value={item.value}>{item.text}</MenuItem>
                    ))}
                </Select>
                {hasError("productType") ? (
                  <FormHelperText error>This is required!</FormHelperText>
                ) : null}
              </FormControl>
              <div className="row">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <TextField
                    required
                    id="required"
                    label={<IntlMessages id="appModule.firstName" />}
                    value={formState.values.firstName}
                    name="firstName"
                    onChange={handleChange}
                    helperText={
                      hasError("firstName")
                        ? formState.errors.firstName[0]
                        : null
                    }
                    error={hasError("firstName")}
                    margin="none"
                  />
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <TextField
                    required
                    id="required"
                    label={<IntlMessages id="appModule.lastName" />}
                    value={formState.values.lastName}
                    name="lastName"
                    onChange={handleChange}
                    helperText={
                      hasError("lastName") ? formState.errors.lastName[0] : null
                    }
                    error={hasError("lastName")}
                    margin="none"
                  />
                </div>
              </div>
              <TextField
                id="required"
                label={<IntlMessages id="appModule.email" />}
                value={formState.values.emailId}
                name="emailId"
                onChange={handleChange}
                helperText={
                  hasError("emailId") ? formState.errors.emailId[0] : null
                }
                error={hasError("emailId")}
                margin="normal"
              />
              <TextField
                id="required"
                label={<IntlMessages id="appModule.phone" />}
                value={formState.values.phoneNo}
                name="phoneNo"
                onChange={handleChange}
                helperText={
                  hasError("phoneNo") ? formState.errors.phoneNo[0] : null
                }
                error={hasError("phoneNo")}
                margin="normal"
              />

              <FormControl className="col-xl-12 col-lg-12 col-md-12">
                <InputLabel id="gender-label">
                  <IntlMessages id="contact.gender" />
                </InputLabel>
                <Select
                  labelId="gender-label"
                  id="gender"
                  variant="outlined"
                  fullWidth
                  label={<IntlMessages id="contact.gender" />}
                  name="gender"
                  value={formState.values.gender || ""}
                  onChange={handleChange}
                  input={<Input id="gender" />}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {dropdown &&
                    dropdown.gender &&
                    dropdown.gender.map((item, i) => (
                      <MenuItem value={item.value}>{item.text}</MenuItem>
                    ))}
                </Select>
                {hasError("productType") ? (
                  <FormHelperText error>This is required!</FormHelperText>
                ) : null}
              </FormControl>
            </div>
          </div>
        </div>

        <div className="modal-box-footer d-flex flex-row">
          <Button
            disabled={formState.values.userType === ""}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            <IntlMessages id="contact.saveContact" />
          </Button>
        </div>
      </Modal>
    );
  }
};

export default AddContact;
