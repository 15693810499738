import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FileExtension } from "util/common";
import Badge from "@material-ui/core/Badge";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    height: "100%",
  },
  dBlock: {
    display: "block",
  },
  deleteicon: {
    color: "#da0d0d",
  },
  fileinput: {
    display: "none",
  },
}));
const FileItem = (props) => {
  const { onHandleDelete, isDeleteFile, className } = props;
  const {
    fileAttachmentPath,
    fileName,

    fileAttachmentId,
  } = props.item;

  let extension;
  extension = FileExtension(fileName).pop();

  const classes = useStyles();
  if (extension === "jpg" || extension === "png" || extension === "jpeg") {
    return (
      <Badge
        badgeContent={
          isDeleteFile === true ? (
            <IconButton
              aria-label="delete"
              className={classes.margin}
              onClick={() => {
                onHandleDelete(fileAttachmentId);
              }}
            >
              <DeleteIcon fontSize="small" className={classes.deleteicon} />
            </IconButton>
          ) : (
            ""
          )
        }
      >
        <LazyLoadImage
          className={clsx("image", classes.root, classes.dBlock, className)}
          alt=""
          src={fileAttachmentPath}
          srcSet={fileAttachmentPath}
          effect="opacity"
          // lazy={true}
        />
      </Badge>
    );
  } else if (extension === "doc") {
  }
  let { thumb } = props;
  if (!thumb) {
    thumb = "https://via.placeholder.com/225x225";
  }
  return (
    <>
      {fileAttachmentPath === null || fileAttachmentPath === "" ? (
        <div
          className="rounded-circle size-40 bg-blue text-center text-white mx-1 mx-md-3"
          style={{ fontSize: 20 }}
        >
          {fileName.charAt(0).toUpperCase()}
        </div>
      ) : (
        <img src={thumb} alt={thumb} />
      )}
    </>
  );
};

export default FileItem;
