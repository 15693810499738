import React from "react";
import useFetch from "effects/use-fetch.effect";
import TreeSelect, { SHOW_PARENT } from "rc-tree-select";
import "./TreeView.css";
const MenuTreeView = (props) => {
  const {
    // url,
    params,
    // categoryId,
    // label,
    // defaultValue,
    onChange,
    // name,
    // loader,
    // options,
    // placeholder,
    treeCheckable,
  } = props;
  const { data } = useFetch("commonmaster/menutreeview/", params);

  /*Treeview code*/
  // React.useEffect(() => {
  //   setTreeview({ ...treeview, value: defaultValue });
  // }, [defaultValue]);
  const [treeview, setTreeview] = React.useState({
    value: "",
    treeDataSimpleMode: {
      id: "key",
      rootPId: 0,
    },
  });
  const onChangeTreeView = (value) => {
    // const { simpleTreeData } = treeview;
    if (value.length === 1) {
      // return;
    }
    // console.log("onChange", value, simpleTreeData);
    setTreeview({ ...treeview, value: value });
    onChange("groupId", value);
  };

  const onSelect = () => {
    // use onChange instead
    // console.log(arguments);
  };

  // const onDataChange = () => {
  //   const { simpleTreeData } = treeview;
  //   const data = simpleTreeData.slice();
  //   data.forEach((i) => {
  //     if (i.key === 11) {
  //       delete i.disabled;
  //     }
  //     if (i.key === 20) {
  //       i.disabled = true;
  //     }
  //   });
  //   setTreeview({ simpleTreeData: data });
  // };

  /** end treeview code*/
  return (
    <React.Fragment>
      {data.length <= 0 ? (
        <span>Please wait... </span>
      ) : (
        <TreeSelect
          dropdownStyle={{ maxHeight: 200, overflow: "auto" }}
          searchPlaceholder="please search Menu"
          transitionName="rc-tree-select-dropdown-slide-up"
          choiceTransitionName="rc-tree-select-selection__choice-zoom"
          placeholder={<i>Menu </i>}
          treeLine
          maxTagTextLength={10}
          // inputValue={defaultValue}
          value={treeview.value}
          treeData={data}
          treeNodeFilterProp="title"
          treeDataSimpleMode={treeview.treeDataSimpleMode}
          treeCheckable={treeCheckable}
          showCheckedStrategy={SHOW_PARENT}
          treeCheckStrictly
          onChange={onChangeTreeView}
          onSelect={onSelect}
        />
      )}
    </React.Fragment>
  );
};

MenuTreeView.defaultProps = {
  defaultValue: "",
};

export default MenuTreeView;
