import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Controller } from "react-hook-form";
import useFetch from "effects/use-fetch.effect";
const MuiAutoComplete = ({
  onChange,
  control,
  url,
  params,
  options,
  name,
  defaultValue,
  label,
}) => {
  const { status, data } = useFetch(url, params);
  return (
    <React.Fragment>
      {status !== "fetched" && status !== "" ? null : (
        <Controller
          render={(props) => (
            <Autocomplete
              {...props}
              options={data}
              getOptionLabel={(option) => option.text}
              renderOption={(option) => <span>{option.text}</span>}
              renderInput={(params) => <TextField {...params} label={label} />}
              onChange={(_, data) => props.onChange(data.value)}
            />
          )}
          name={name}
          control={control}
        />
      )}
    </React.Fragment>
  );
};

export default MuiAutoComplete;
