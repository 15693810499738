import fetch from "isomorphic-fetch";

const headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  authorization:
    "bearer " +
    (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
  OPTIONS: "",
};

export async function post(url, data) {
  const response = await fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(data),
  });
  return response;
}
export async function get(url) {
  const response = await fetch(url, {
    method: "GET",
    headers,
  });
  return await response.json();
}
