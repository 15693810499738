export const TOKEN = "token";
export const LOGIN_USER_DETAIL = "contact/detail";

// export const HOME_BUSINESS = "home/featuredBusiness";

export const SIDE_MENU = "app/configuration/menus";
export const OUTLET_SETTING = "app/configuration/outlet/settings";
/*Common DropDown Apis Start*/
/**Pass Module with with api
 * 1 Gender
 * 2 ProductType
 * 3 ProductImport
 * 4 Product
 * 5 Order
 * 6 TaxType
 */
export const COMMON_MASTER = "commonmaster/";
export const MODULE_DROPDOWN = "commonmaster/dropdown/";
export const ACTIVE_PRIZE_LIST="productprize/activelist";
export const GET_GROUP = "commonmaster/optiongroup/1";
export const GET_PRODUCTDROPDOWN = "product/ProductDropdown";
export const APP_DROPDOWN_KEY = {
  OrderStatus: 9,
  PaymentMode: 10,
  FilterOrderStatus: 12,
  ProductType: "producttype",
  category: "category",
};

/*Common DropDown Apis End */

/*Shop Apis Start*/
export const SHOP_PRODUCT = "shop/product";
export const SHOP_GROUP = "group";

/*Shop Apis End */

/*Import Apis Start*/
export const IMPORT_PRODUCT = "product/import-excel";
export const EXPORT_PRODUCT = "product/export-excel";
export const UPLOAD_PRODUCT_IMAGE = "product/upload-image";

/*Import Apis End*/
/*File Upload API  Start*/
export const FILE_PATH = "dynamic/files/";
export const FILE = "dynamic/files";

/*File Upload API End */

export const GROUP = "group/griddata";
export const ADMIN_PRODUCT_PRIZE = "productprize";

/**Contact APi  Constant Start */
export const GET_OUTLET_LIST = "outletlist";
export const SIGNUP_BUSINESS = "business";
export const SIGNUP_VERIFYOTP = "contact/verifyOTP";
export const SIGNUP_EXTERNAL_BUSINESS = "external/business/registration";
export const CONTACT = "contact/";
export const CONTACT_DETAIL = "contact/detail/";
export const STAFF = "staff/";
export const CUSTOMER = "customer/";
export const ADMIN_CUSTOMER = "admin/customer/";
export const OUTLET = "outlet/";
export const STAFF_WORKING_HOUR = "WorkingHour/";

/**Contact APi  Constant end */

export const PRODUCT_REVIEW = "review/product";
export const PRODUCT_REVIEW_DETAIL = "review/detail/";
export const PRODUCT_REVIEW_DELETE = "review/";

/**Dynamic  APi  Constant Start */
export const PAGE = "configuration/page";
export const PAGE_CONFIGURATION = "configuration/page/elements/";
export const PAGE_REPORT = "dynamic/Report";
export const PAGE_ViewData = "dynamic/GetViewData";
export const PageConfigration = {
  Menu: PAGE_CONFIGURATION + 15,
  Orders: PAGE_CONFIGURATION + 8,
  Module: PAGE_CONFIGURATION + 8,
};

/**Dynamic APi  Constant End */

/*Order Api Start*/
export const ORDER_STATUSUPDATE = "order/statusupdate";
export const ORDERDETAIL_STATUSUPDATE = "orderdetail/statusupdate";
export const ORDER_LINEITEM_CANCEL = "order/itemcancel/";
export const ORDER_EVENT_CALENDER = "calender/orders";

/*Order Apis End */

/*POS Module Api Start*/
export const PRODUCT = "product/";
export const PRORDUCT_SELL = "product/sell";
export const POS_BUSINESS_STAFF = "pos/business/staff";
export const POS_BUSINESS_STAFF_AVAILABLESLOTS = "pos/business/staffslots";
export const POS_SAVE_ORDER = "pos/save-order";
export const ORDER_RESCHEDULE = "sell/order/reschedule";
export const OUTLET_VOUCHERS = "vouchers";
export const VARIFY_VCODE = "voucher/varifyvoucher";
export const PRODUCT_DROPDOWN = "product/Productlist";
/*POS Module Api End */

// import product excel
