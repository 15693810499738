import { all, call } from "redux-saga/effects";
// import mailSagas from "../sagas/Mail";
// import toDoSagas from "../sagas/Todo";
// import contactSagas from "../sagas/Contact";
// import chatSagas from "../sagas/Chat";
import { authSagas } from "../sagas/Auth";
import { orderSagas } from "../sagas/Order.Saga";
import { sellSagas } from "../sagas/Sell.Saga";
import { settingsSagas } from "../sagas/Settings";
export default function* rootSaga(getState) {
  yield all([
    call(settingsSagas),
    call(sellSagas),
    call(authSagas),
    call(orderSagas),
  ]);
}
