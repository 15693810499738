import {  OrderActionTypes } from 'constants/ActionTypes';


  export const showOrderMessage = (message) => {
    return {
      type: OrderActionTypes.SHOW_MESSAGE,
      payload: message
    };
  };
  export const fetchPageConfiguration= (data) => {
    return {
      type: OrderActionTypes.FETCH_PAGE_CONFIGURATION_START,
      payload: data
    };
  };
  export const fetchPageConfigurationSucess= (data) => {
    return {
      type: OrderActionTypes.FETCH_PAGE_CONFIGURATION_SUCCESS,
      payload: data
    };
  };
export const fetchAllOrder = (data) => {
    return {
      type: OrderActionTypes.FETCH_ALL_ORDER,
      payload: data
    };
  };
  export const fetchOrdersSuccess = (orders) => {
    return {
      type: OrderActionTypes.FETCH_ALL_ORDER_SUCCESS,
      payload: orders
    }
  };
 
  export const onChangePage = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_GRID_PAGE_CHANGE,
      payload: data
    };
  };

  export const onChangePageSize = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_GRID_PAGESIZE_CHANGE,
      payload: data
    };
  };
  export const onOrderSelect = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_GRID_ROW_SELECT,
      payload: data
    };
  };
  export const onOrderDeSelect = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_GRID_ROW_DESELECT     
    };
  };
  export const onOrderSort = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_GRID_COLMN_SORT,
      payload: data
    };
  };
  export const onFilterChange = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_GRID_FILTERCHANGE,
      payload: data
    };
  };

  
export const onOrderUpdate = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_UPDATE,
      payload: data
    };
  };
   
export const onOrderUpdateSucess = (data) => {
  return {
    type: OrderActionTypes.ON_ORDER_UPDATE_SUCCESS
    
  };
};
  export const onDeleteOrder = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_DELETE,
      payload: data
    };
  };
  
  export const onOrderViewClose = () => {
    return {
      type: OrderActionTypes.ON_ORDER_VIEW_CLOSE
    };
  };

  
  export const onOrderView = (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_VIEW,
      payload: data
    };
  };
  export const onToggleFilter= () => {
    return {
      type: OrderActionTypes.ON_ORDER_TOGGLE_FILTER
    };
  };
  export const fetchOrderViewDetails= (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_VIEW_DETAIL,
      payload: data
    };
  };
  
  export const fetchOrderViewDetailsSucess= (data) => {
    return {
      type: OrderActionTypes.ON_ORDER_VIEW_DETAIL_SUCCESS,
      payload: data
    };
  };