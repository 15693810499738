import AppConfig from "./AppConfig";
import moment from "moment";
async function get(url, body) {
  const requestOptions = {
    method: body ? "POST" : "GET",
    headers: {
      "Content-Type": "application/json",
      authorization:
        "bearer " +
        (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
      "Access-Control-Allow-Origin": "*",
    },
  };
  if (body) {
    requestOptions.body = body ? JSON.stringify(body) : "";
  }
  url = AppConfig.env("url") + url;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}
async function getJson(url) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}
async function postFile(url, body) {
  const requestOptions = {
    method: "POST",
    headers: {
      authorization:
        "bearer " +
        (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
      "Access-Control-Allow-Origin": "*",
      TimezoneOffset: moment().utcOffset(),
    },
    body: body,
  };
  url = AppConfig.env("url") + url;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}
async function post(url, body) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization:
        "bearer " +
        (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
      "Access-Control-Allow-Origin": "*",
      TimezoneOffset: moment().utcOffset(),
    },
    body: JSON.stringify(body),
  };
  url = AppConfig.env("url") + url;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}

async function put(url, body) {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization:
        "bearer " +
        (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
      "Access-Control-Allow-Origin": "*",
      TimezoneOffset: moment().utcOffset(),
    },
    body: JSON.stringify(body),
  };
  url = AppConfig.env("url") + url;
  const response = await fetch(url, requestOptions);
  return handleResponse(response);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization:
        "bearer " +
        (localStorage.getItem("token") ? localStorage.getItem("token") : ""),
      "Access-Control-Allow-Origin": "*",
      TimezoneOffset: moment().utcOffset(),
    },
  };
  url = AppConfig.env("url") + url;
  return fetch(url, requestOptions).then(handleResponse);
}

// helper functions

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      //const error = (data && data.message) || response.statusText;
      return Promise.resolve(data);
    }

    return data;
  });
}

const fetchWrapper = {
  get,
  post,
  postFile,
  put,
  getJson,
  delete: _delete,
};
export default fetchWrapper;
