import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import fetchWrapper from "util/fetch-wrapper";
import { SIGNUP_EXTERNAL_BUSINESS } from "../services/APIUrl/Api";
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from "actions/Auth";

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = {
      email: "demo@gmail.com",
      password: "123456",
    };
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push("/");
    }
  }
  responseFacebook = async (data) => {
    console.log(data);

    const { graphDomain, email, name, userID, picture } = data;

    // data = data.profileObj;
    let signUp_data = {
      name: name,
      emailId: email,
      phoneNo: "",
      provider: graphDomain,
      provider_id: userID,
      provider_pic: picture.data.url,
      categoryId: 0,
    };
    const response = await fetchWrapper.post(
      SIGNUP_EXTERNAL_BUSINESS,
      signUp_data
    );
    if (response.isSuccessful || response.data === "Record already exists") {
      this.props.userSignIn({
        email: email,
        password: "",
        provider: graphDomain,
      });
    }
  };
  responseGoogle = async (data) => {
    console.log(data);
    // data = data.profileObj;
    if (data.profileObj) {
      let signUp_data = {
        name: data.profileObj.name,
        emailId: data.profileObj.email,
        phoneNo: "",
        provider: data.tokenObj.idpId,
        provider_id: data.profileObj.googleId,
        provider_pic: data.profileObj.imageUrl,
        categoryId: 0,
      };
      const response = await fetchWrapper.post(
        SIGNUP_EXTERNAL_BUSINESS,
        signUp_data
      );
      if (response.isSuccessful || response.data === "Record already exists") {
        this.props.userSignIn({
          email: data.profileObj.email,
          password: "",
          provider: data.tokenObj.idpId,
        });
      }
    }
  };
  render() {
    const { email, password } = this.state;
    const { showMessage, loader, alertMessage } = this.props;
    return (
      <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="favsha">
              <img
                src={require("assets/images/logo.png")}
                alt="favsha"
                title="favsha"
                style={{ width: "177px" }}
              />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header mb-4">
              <h1>
                <IntlMessages id="appModule.email" />
              </h1>
            </div>

            <div className="app-login-form">
              <form>
                <fieldset>
                  <TextField
                    label={<IntlMessages id="appModule.email" />}
                    fullWidth
                    onChange={(event) =>
                      this.setState({ email: event.target.value })
                    }
                    defaultValue={email}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />
                  <TextField
                    type="password"
                    label={<IntlMessages id="appModule.password" />}
                    fullWidth
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    defaultValue={password}
                    margin="normal"
                    className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button
                      onClick={() => {
                        this.props.showAuthLoader();
                        this.props.userSignIn({ email, password });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>

                    <Link to="/signup">
                      <IntlMessages id="signIn.signUp" />
                    </Link>
                  </div>

                  <div className="app-social-block my-1 my-sm-3">
                    <IntlMessages id="signIn.connectWith" />
                    <ul className="social-link">
                      <li>
                        <FacebookLogin
                          appId="147522900290727"
                          autoLoad={false}
                          fields="name,email,picture"
                          callback={this.responseFacebook}
                          render={(renderProps) => (
                            <IconButton
                              className="icon"
                              onClick={renderProps.onClick}
                              // onClick={() => {
                              //   this.props.showAuthLoader();
                              //   this.props.userFacebookSignIn();
                              // }}
                            >
                              <i className="zmdi zmdi-facebook" />
                            </IconButton>
                          )}
                        />
                      </li>

                      <li>
                        <GoogleLogin
                          clientId="73442081070-f0bg2fti6ku186ri57gr7srvqrmartjt.apps.googleusercontent.com"
                          render={(renderProps) => (
                            <IconButton
                              className="icon"
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                            >
                              <i className="zmdi zmdi-google-plus" />
                            </IconButton>
                          )}
                          buttonText="Login"
                          onSuccess={this.responseGoogle}
                          onFailure={this.responseGoogle}
                          cookiePolicy={"single_host_origin"}
                        />
                        {/*    <IconButton
                          className="icon"
                          onClick={() => {
                            this.props.showAuthLoader();
                            this.props.userGoogleSignIn();
                          }}
                        >
                          <i className="zmdi zmdi-google-plus" />
                        </IconButton> */}
                      </li>
                    </ul>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
        {loader && (
          <div className="loader-view">
            <CircularProgress />
          </div>
        )}
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer />
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
})(SignIn);
