import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Menu from "components/TopNav/Menu";

class TopNav extends React.Component {
  render() {
    return (
      <div className={`app-top-nav d-none d-md-block ${this.props.styleName}`}>
        <div className="d-flex app-toolbar align-items-center">
          <Menu sideNav={this.props.sideNav} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { sideNav } = settings;
  return { sideNav };
};
export default withRouter(connect(mapStateToProps, null)(TopNav));

TopNav.defaultProps = {
  styleName: "",
};
