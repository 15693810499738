import React from "react";
// import Button from '@material-ui/core/Button';
// import IntlMessages from 'util/IntlMessages';

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Copyright Demo &copy; 2020</span>
    </footer>
  );
};
export default Footer;
