import { applyMiddleware, compose, createStore } from "redux";
import reducers from "./root-reducer";
import createBrowserHistory from "history/createBrowserHistory";

import { routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./root-sage";
import logger from "redux-logger";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware, routeMiddleware];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState) {
  if (process.env.NODE_ENV === "development") {
    middlewares.push(logger);
  }
  const store = createStore(
    reducers(history),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("./root-reducer", () => {
      const nextRootReducer = require("./root-reducer");
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export { history };
