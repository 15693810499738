import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
// import contactList from "../data/contactList";
import ContactList from "components/contact/ContactList";
import AppModuleHeader from "components/AppModuleHeader";
import AddContact from "components/contact/AddContact";
import IntlMessages from "util/IntlMessages";
import CustomScrollbars from "util/CustomScrollbars";
import { ItemGet, ItemDelete } from "services/Common";
import { CONTACT, MODULE_DROPDOWN, COMMON_MASTER } from "services/APIUrl";
// let contactId = 723812738;

const filterOptions = [
  {
    id: 2,
    name: "Staff",
    icon: "zmdi-time-restore",
  },
];

class Contact extends Component {
  ContactSideBar = () => {
    return (
      <div className="module-side">
        <div className="module-side-header">
          <div className="module-logo">
            <i className="zmdi zmdi-account-box mr-4" />
            <span>
              <IntlMessages id="chat.contacts" />
            </span>
          </div>
        </div>

        <div className="module-side-content">
          <CustomScrollbars
            className="module-side-scroll scrollbar"
            style={{
              height:
                this.props.width >= 1200
                  ? "calc(100vh - 200px)"
                  : "calc(100vh - 80px)",
            }}
          >
            <div className="module-add-task">
              <Button
                className="jr-btn btn-block"
                variant="contained"
                color="primary"
                aria-label="add"
                onClick={this.onAddContact}
              >
                <i className="zmdi zmdi-account-add zmdi-hc-fw" />
                <span>Add New Contact</span>
              </Button>
            </div>
            <div className="module-side-nav">
              <ul className="module-nav">
                {filterOptions.map((option) => (
                  <li key={option.id} className="nav-item">
                    <span
                      className={`jr-link ${
                        option.id === this.state.selectedSectionId
                          ? "active"
                          : ""
                      }`}
                      onClick={this.onFilterOptionSelect.bind(this, option)}
                    >
                      <i className={`zmdi ${option.icon}`} />
                      <span>{option.name}</span>
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </CustomScrollbars>
        </div>
      </div>
    );
  };

  addFavourite = (data) => {
    this.setState({
      alertMessage: data.starred
        ? "Contact removed as star"
        : "Contact marked as star",
      showMessage: true,
      contactList: this.state.contactList.map((contact) =>
        contact.id === data.id
          ? {
              ...contact,
              starred: !data.starred,
            }
          : contact
      ),
      allContact: this.state.allContact.map((contact) =>
        contact.id === data.id
          ? {
              ...contact,
              starred: !data.starred,
            }
          : contact
      ),
    });
  };
  onContactSelect = (data) => {
    data.selected = !data.selected;
    let selectedContacts = 0;
    // const contactList = this.state.contactList.map((contact) => {
    //   if (contact.selected) {
    //     selectedContacts++;
    //   }
    //   if (contact.id === data.id) {
    //     if (contact.selected) {
    //       selectedContacts++;
    //     }
    //     return data;
    //   } else {
    //     return contact;
    //   }
    // });
    this.setState({
      selectedContacts: selectedContacts,
      contactList: this.state.contactList,
    });
  };

  onAddContact = () => {
    this.setState({ addContactState: true });
  };
  onContactClose = () => {
    this.setState({ addContactState: false });
  };
  onFilterOptionSelect = (option) => {
    switch (option.name) {
      case "All contacts": {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          contactList: this.state.allContact,
        });
        break;
      }
      case "Staff": {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          contactList: this.state.allContact.filter(
            (contact) => contact.designation === option.name
          ),
        });
        break;
      }
      case "Customer": {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          contactList: this.state.allContact.filter(
            (contact) => contact.designation === option.name
          ),
        });
        break;
      }
      default: {
        this.setState({
          selectedSectionId: option.id,
          filterOption: option.name,
          contactList: this.state.allContact,
        });
      }
    }
  };
  onSaveContact = (data) => {
    let isNew = true;
    const contactList = this.state.contactList.map((contact) => {
      if (contact.id === data.id) {
        isNew = false;
        return data;
      } else {
        return contact;
      }
    });
    if (isNew) {
      this.fetchContacts();
    }
    this.setState({
      alertMessage: isNew
        ? "Contact Added Successfully"
        : "Contact Updated Successfully",
      showMessage: true,
      contactList: contactList,
      allContact: contactList,
    });
    // this.onFilterOptionSelect(this.state.filterOption);
  };
  onDeleteContact = (data) => {
    let param = { url: CONTACT + data.id };
    ItemDelete(param).then((response) => {
      if (response.isSuccessful) {
        this.setState({
          alertMessage: "Contact Deleted Successfully",
          showMessage: true,
          allContact: this.state.allContact.filter(
            (contact) => contact.id !== data.id
          ),
          contactList: this.state.allContact.filter(
            (contact) => contact.id !== data.id
          ),
        });
      }
    });
  };
  onDeleteSelectedContact = () => {
    const contacts = this.state.allContact.filter(
      (contact) => !contact.selected
    );
    this.setState({
      alertMessage: "Contact Deleted Successfully",
      showMessage: true,
      allContact: contacts,
      contactList: contacts,
      selectedContacts: 0,
    });
  };
  filterContact = (userName) => {
    const { filterOption } = this.state;
    if (userName === "") {
      this.setState({ contactList: this.state.allContact });
    } else {
      const filterContact = this.state.allContact.filter(
        (contact) =>
          contact.name.toLowerCase().indexOf(userName.toLowerCase()) > -1
      );
      if (filterOption === "All contacts") {
        this.setState({ contactList: filterContact });
      } else if (filterOption === "Frequently contacted") {
        this.setState({
          contactList: filterContact.filter((contact) => contact.frequently),
        });
      } else if (filterOption === "Starred contacts") {
        this.setState({
          contactList: filterContact.filter((contact) => contact.starred),
        });
      }
    }
  };
  handleRequestClose = () => {
    this.setState({
      showMessage: false,
    });
  };
  getAllContact = () => {
    // let contactList = this.state.allContact.map((contact) =>
    //   contact
    //     ? {
    //         ...contact,
    //         selected: true,
    //       }
    //     : contact
    // );
    this.setState({
      selectedContacts: this.state.contactList.length,
      allContact: this.state.contactList,
      contactList: this.state.contactList,
    });
  };
  getUnselectedAllContact = () => {
    // let contactList = this.state.allContact.map((contact) =>
    //   contact
    //     ? {
    //         ...contact,
    //         selected: false,
    //       }
    //     : contact
    // );
    this.setState({
      selectedContacts: 0,
      allContact: this.state.contactList,
      contactList: this.state.contactList,
    });
  };

  constructor() {
    super();

    this.state = {
      loader: true,
      noContentFoundMessage: "No contact found in selected folder",
      alertMessage: "",
      showMessage: false,
      selectedSectionId: 1,
      drawerState: false,
      user: {
        name: "Robert Johnson",
        email: "robert.johnson@example.com",
        avatar: "https://via.placeholder.com/150x150",
      },
      searchUser: "",
      filterOption: "All contacts",
      allContact: [],
      contactList: [],
      dropdown: {},
      selectedContact: null,
      selectedContacts: 0,
      addContactState: false,
    };
  }

  onAllContactSelect() {
    const selectAll =
      this.state.selectedContacts < this.state.contactList.length;
    if (selectAll) {
      this.getAllContact();
    } else {
      this.getUnselectedAllContact();
    }
  }
  fetchContacts() {
    let param = { url: CONTACT };
    ItemGet(param).then((response) => {
      if (response.data && response.data.length) {
        this.setState({
          loader: false,
          selectedContacts: 0,
          allContact: response.data.filter(
            (contact) => contact.designation === "Staff"
          ),
          contactList: response.data.filter(
            (contact) => contact.designation === "Staff"
          ),
        });
      }
    });
  }
  fetchDropdowsData() {
    let dropdown = {};
    let param = { url: CONTACT };
    param = { url: MODULE_DROPDOWN + 1 };

    ItemGet(param).then((response) => {
      if (response.data && response.data.length) {
        dropdown.usertype = response.data;
        this.setState({
          dropdown: dropdown,
        });
      }
    });
    param = { url: COMMON_MASTER + 1 };
    ItemGet(param).then((response) => {
      if (response.data && response.data.length) {
        dropdown.gender = response.data;
        this.setState({
          dropdown: dropdown,
        });
      }
    });
  }
  componentWillMount() {
    this.fetchDropdowsData();
    this.fetchContacts();
  }
  updateContactUser(evt) {
    this.setState({
      searchUser: evt.target.value,
    });
    this.filterContact(evt.target.value);
  }

  onToggleDrawer() {
    this.setState({
      drawerState: !this.state.drawerState,
    });
  }

  render() {
    const {
      dropdown,
      contactList,
      addContactState,
      selectedContacts,
      alertMessage,
      showMessage,
      noContentFoundMessage,
    } = this.state;
    return (
      <div className="app-wrapper">
        <div className="app-module animated slideInUpTiny animation-duration-3">
          <div className="module-box">
            <div className="module-box-header">
              <IconButton
                className="drawer-btn d-block d-xl-none"
                aria-label="Menu"
                onClick={this.onToggleDrawer.bind(this)}
              >
                <i className="zmdi zmdi-menu" />
              </IconButton>
              <AppModuleHeader
                placeholder="Search contact"
                notification={false}
                apps={false}
                user={this.state.user}
                onChange={this.updateContactUser.bind(this)}
                value={this.state.searchUser}
              />
            </div>
            <div className="module-box-content">
              <div className="module-box-topbar d-none">
                <Checkbox
                  color="primary"
                  indeterminate={
                    selectedContacts > 0 &&
                    selectedContacts < contactList.length
                  }
                  checked={selectedContacts > 0}
                  onChange={this.onAllContactSelect.bind(this)}
                  value="SelectMail"
                />
                {selectedContacts > 0 && (
                  <IconButton
                    className="icon-btn"
                    onClick={this.onDeleteSelectedContact.bind(this)}
                  >
                    <i className="zmdi zmdi-delete" />
                  </IconButton>
                )}
              </div>
              <CustomScrollbars
                className="module-list-scroll scrollbar"
                style={{
                  height:
                    this.props.width >= 1200
                      ? "calc(100vh - 200px)"
                      : "calc(100vh - 240px)",
                }}
              >
                {contactList.length === 0 ? (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    {noContentFoundMessage}
                  </div>
                ) : (
                  <ContactList
                    contactList={contactList}
                    dropdown={dropdown}
                    addFavourite={this.addFavourite.bind(this)}
                    onContactSelect={this.onContactSelect.bind(this)}
                    onDeleteContact={this.onDeleteContact.bind(this)}
                    onSaveContact={this.onSaveContact.bind(this)}
                  />
                )}
              </CustomScrollbars>
            </div>
          </div>
        </div>

        <AddContact
          open={addContactState}
          contact={{
            id: 0,
            name: "",
            thumb: "",
            email: "",
            phone: "",
            designation: "",
            selected: false,
            starred: false,
            frequently: false,
            fileReferenceCode: "",
          }}
          onSaveContact={this.onSaveContact}
          onContactClose={this.onContactClose}
          onDeleteContact={this.onDeleteContact}
          dropdown={dropdown}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showMessage}
          autoHideDuration={3000}
          onClose={this.handleRequestClose}
          ContentProps={{
            "aria-describedby": "message-id",
          }}
          message={<span id="message-id">{alertMessage}</span>}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { width } = settings;
  return { width };
};
export default connect(mapStateToProps)(Contact);
