const getBaseUrl = () => {
  var _baseUrl = "/";
  var _isProdution = document.location.host.indexOf(".favsha.com") !== -1;
  if (_isProdution) {
    _baseUrl = "/" + window.location.href.split("/")[3] + "/";
  }
  return _baseUrl;
};
export const FilePath = (fileurl) => {
  let baseUrl = AppConfig.env("baseUrl");
  return baseUrl + AppConfig.fileFolder + fileurl;
};
const getAppBaseUrl = () => {
  var _baseUrl = "/";
  var _isProdution = document.location.host.indexOf(".favsha.com") !== -1;
  if (_isProdution) {
    _baseUrl = "/" + window.location.href.split("/")[3] + "/";
  } else {
    _baseUrl = window.location.protocol + "//" + window.location.host;
  }
  return _baseUrl;
};

// const getApiUrl = () => {
//     return `${window.location.protocol}//${window.location.host}/api/`;
// }

const AppConfig = {
  appMode: window.location.host.search("admin") === 0 ? "prod" : "dev",
  env: function(config) {
    if (this.appMode === "prod") {
      return this.prod[config];
    } else {
      return this.dev[config];
    }
  },
  textAutoSaveDelay: 4000, // in milisecond;
  modifiedOnDateFormat: "LLP",
  baseUrl: getBaseUrl(),
  fileFolder: "UploadedFiles/",
  appBaseUrl: getAppBaseUrl(),
  reduxLogger: false,
  axiosLogger: false,
  dev: {
    deviceType: "desktop", //desktop,mobile,IOS
    // baseUrl: "https://api.favsha.com/",
    // url: "https://api.favsha.com/api/v1/",
    baseUrl: "http://localhost:59876/",
    url: "http://localhost:59876/api/v1/",
    userId: "123",
    connectionId: "",
    googleapiurl:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDGSZR9fx0fmT4EV_6Xm4PrCso8e0Go0xM&libraries=places",
  },
  prod: {
    deviceType: "desktop", //desktop,mobile,IOS
    // baseUrl: 'https://api.favsha.com/',
    // url: 'https://api.favsha.com/api/v1/',
    baseUrl: "http://localhost:59876/",
    url: "http://localhost:59876/api/v1/",
    userId: "",
    connectionId: "",
    googleapiurl:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDGSZR9fx0fmT4EV_6Xm4PrCso8e0Go0xM&libraries=places",
  },
};

export default AppConfig;
