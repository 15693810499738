import { useEffect, useState, useRef } from "react";
import { getAxios } from "util/axios-utils";

const useFetch = (url, params) => {
  const cache = useRef({});
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState([]);
  useEffect(() => {
    if (!url) return;
    
    const fetchData = async () => {
      setStatus("fetching");
      if (cache.current[url]) {
        const data = cache.current[url];
        setData(data);
        setStatus("fetched");
        // console.log(data);
      } else {
        const response = await getAxios(url, params);
        if (response.data) {
          cache.current[url] = response.data.data; // set response in cache;
          // console.log(response.data.data);
          if (response.data.data === 1) {
            setData(response.data.data[0]);
          } else setData(response.data.data);
          setStatus("fetched");
        }
      }
    };

    fetchData();
  }, [url, params]);

  return { status, data };
};

export default useFetch;
