import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";

const eCommerce = ({ match }) => (
  <div className="app-wrapper">
    <Switch>
      <Redirect exact from={`${match.url}/`} to={`${match.url}/orders`} />
      <Route
        path={`${match.url}/orders-8`}
        component={asyncComponent(() => import("./routes/order"))}
      />
      <Route
        path={`${match.url}/appointments/:orderDetailId`}
        component={asyncComponent(() => import("./routes/orderDetail"))}
      />

      <Route
        path={`${match.url}/sell`}
        component={asyncComponent(() => import("./routes/sell"))}
      />
      <Route
        path={`${match.url}/calender`}
        component={asyncComponent(() => import("./routes/calender"))}
      />
      <Route
        path={`${match.url}/appointments-53`}
        component={asyncComponent(() => import("./routes/appointments"))}
      />
      <Route
        path={`${match.url}/vouchers-55`}
        component={asyncComponent(() => import("./routes/vouchers"))}
      />
      {/*  
      <Route
        path={`${match.url}/sales`}
        component={asyncComponent(() => import("./routes/sales"))}
     /> */}
      <Route
        component={asyncComponent(() =>
          import("app/routes/extraPages/routes/404")
        )}
      />
    </Switch>
  </div>
);

export default eCommerce;
